export const detectSocialMedia = (url) => {
  const facebookRegex = /facebook\.com/i;
  const youtubeRegex = /youtube\.com/i;
  const instagramRegex = /instagram\.com/i;
  const tiktokRegex = /tiktok\.com/i;
  const linkedinRegex = /linkedin\.com/i;
  const behanceRegex = /behance\.net/i;

  if (facebookRegex.test(url)) {
    return "FACEBOOK";
  } else if (youtubeRegex.test(url)) {
    return "YOUTUBE";
  } else if (instagramRegex.test(url)) {
    return "INSTAGRAM";
  } else if (tiktokRegex.test(url)) {
    return "TIKTOK";
  } else if (linkedinRegex.test(url)) {
    return "LINKEDIN";
  } else if (behanceRegex.test(url)) {
    return "BEHANCE";
  } else {
    return "WEBSITE";
  }
};
