import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/index";
import { composeWithDevTools } from '@redux-devtools/extension';

const middleware = [thunk];
const initialState = {};
const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

/* import { configureStore } from '@reduxjs/toolkit'

import rootReducer from "../reducers/index";

const initialState = {};


const store = configureStore({ reducer: rootReducer ,  initialState, })
// The store now has redux-thunk added and the Redux DevTools Extension is turned on


export default store; */