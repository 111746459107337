import { GET_ALL_PHONE_PREFIXES } from "../../constants/action-types";

const initialState = {
  phoneNumberPrefixes: [],
};

const utilsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PHONE_PREFIXES:
      return { ...state, phoneNumberPrefixes: action.data };
    default:
      return state;
  }
};

export default utilsReducer;
