import { Col, Row } from "react-bootstrap";

const NwCard = (props) => {
  return (
    <Row className="network-div mb-0 mb-sm-3 d-flex flex-row align-items-center justify-content-center">
      <Col xs={12} lg={5}>
        <img
          className={"network-div-img avatar-img me-sm-4 me-0"}
          src={props?.data?.connectionProfileImageUrl || props.data.image}
          alt="network-img"
        />
      </Col>
      <Col xs={12} lg={7}>
        <div className={"network-div-content fw-700 d-none d-md-block"}>
          <p className={"network-div-title m-0 font-14 fw-700 text-black"}>
            {props.data.name}
          </p>
          <p className={"network-div-job m-0 text-secondary font-14"}>
            {props.data.description}
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default NwCard;
