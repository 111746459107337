import { IS_LOADING } from "../../constants/action-types";
const INITIAL_STATE = {
    isLoading: false,
};

const loaderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case IS_LOADING:
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export default loaderReducer;