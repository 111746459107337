import Header from "../Commons/Header";
import Footer from "../Commons/Footer";
import { Outlet } from "react-router-dom";

export default function Layout(props) {
    return (
        <>
            <Header />
            <div>
                <Outlet />
            </div>
            <Footer />
        </>
    );
}