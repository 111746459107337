export default function NwCard(props) {
    return (
        <>
            <div className={"network-div mb-0 mb-sm-3 d-flex flex-row align-items-center"}>
                <img className={"network-div-img avatar-img me-sm-4 me-0"} src={props.data.image} alt="network-img" />
                <div className={"network-div-content d-none d-md-block"}>
                    <p className={"network-div-title fw-700 m-0 font-16 text-black"}>{props.data.name}</p>
                    <p className={"network-div-job fw-700 m-0 text-secondary font-14"}>{props.data.description}</p>
                </div>
            </div>
        </>
    );
}