import { useState } from "react";
import { useAxios } from "../api/useAxios";

const useCreatives = () => {
  const { get } = useAxios();
  const [errorMessage, setErrorMessage] = useState();
  const [userData, setUserData] = useState();

  const handleGetCreativeProfile = async (user_id) => {
    if (!user_id) return;

    const res = await get(`/creatives/details/${user_id}`);

    res?.status === "ok"
      ? setUserData(res.creativeProfile)
      : setErrorMessage(
          res?.errorMessage ||
            `Failed to fetch Creative with user id: ${user_id}`
        );
  };

  return {
    userData,
    errorMessage,
    handleGetCreativeProfile,
  };
};

export default useCreatives;
