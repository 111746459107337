const Logo = (props) => {
  return (
    <a href="https://www.cultcreativeasia.com">
      <img
        style={props.style}
        className="me-3"
        src={
          "/assets/images/logos/cc_logo2.png"
          // props.img === "desktop-logo"
          //   ? "/assets/images/logos/logo-c.png"
          //   : "/assets/images/logos/logo-black.png"
        }
        alt="logo"
      />
    </a>
  );
};

export default Logo;
