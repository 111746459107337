import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAILURE,
  LOGOUT_SUCCESS,
  EMPTY_STATUS,
  GET_ALL_RESUMES,
  UPLOAD_RESUME_SUCCESS,
  UPLOAD_RESUME_FAILURE,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
} from "../../constants/action-types";

const initialState = {
  user: {},
  isLoggedIn: localStorage.getItem("token2") ? true : false,
  status: "",
  resumes: [],
};

const userReducer = (state = initialState, action) => {
  if (action.type === LOGIN_SUCCESS) {
    return {
      ...state,
      isLoggedIn: false,
      status: LOGIN_SUCCESS,
    };
  }
  if (action.type === LOGIN_FAILURE) {
    return {
      ...state,
      isLoggedIn: false,
      status: LOGIN_FAILURE,
    };
  }
  if (action.type === AUTHENTICATE_USER_SUCCESS) {
    return {
      ...state,
      user: action.data,
      isLoggedIn: true,
      status: AUTHENTICATE_USER_SUCCESS,
    };
  }
  if (action.type === AUTHENTICATE_USER_FAILURE) {
    return {
      ...state,
      isLoggedIn: false,
      status: AUTHENTICATE_USER_FAILURE,
    };
  }
  if (action.type === LOGOUT_SUCCESS) {
    return {
      ...state,
      isLoggedIn: false,
      user: {},
      status: LOGOUT_SUCCESS,
    };
  }
  if (action.type === EMPTY_STATUS) {
    return {
      ...state,
      status: EMPTY_STATUS,
    };
  }
  if (action.type === GET_ALL_RESUMES) {
    return {
      ...state,
      resumes: action.data,
    };
  }
  if (action.type === UPLOAD_RESUME_SUCCESS) {
    return {
      ...state,
      status: UPLOAD_RESUME_SUCCESS,
    };
  }
  if (action.type === UPLOAD_RESUME_FAILURE) {
    return {
      ...state,
      status: UPLOAD_RESUME_FAILURE,
    };
  }
  if (action.type === SIGNUP_USER_SUCCESS) {
    return {
      ...state,
      status: SIGNUP_USER_SUCCESS,
    };
  }
  if (action.type === SIGNUP_USER_FAILURE) {
    return {
      ...state,
      status: SIGNUP_USER_FAILURE,
    };
  }
  return state;
};

export default userReducer;
