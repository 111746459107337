import { toast } from "react-toastify";

export const successAlert = (msg, duration) => {
  toast.success(msg, {
    autoClose: duration || 5000,
  });
};
export const errorAlert = (error, duration) => {
  toast.error(error, {
    autoClose: duration || 5000,
  });
};

export function makeFormData(newDataObj) {
  let data = new FormData();
  for (const key in newDataObj) {
    if (key === "fileData" && newDataObj[key]?.name) {
      data.append(key, newDataObj[key], newDataObj[key].name);
    } else {
      data.append(key, newDataObj[key]);
    }
  }
  return data;
}

export function getBase64(file, cb) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.error("Error: ", error);
  };
}

export const GetFileData = async (fileObject) => {
  const fileRead = await readFile(fileObject);
  const newFile = {
    url: fileRead,
    filename: fileObject.name,
  };
  return newFile;
};

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function () {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
};
