import { useEffect, useState } from "react";
import { useAxios } from "../../api/useAxios";
import ProjectCard from "./Project/ProjectCard";
import Network from "../../components/Profile/Network";
import { Link, useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";
import Loader from "../../components/Commons/Loader";

const ProfileOverviewContent = ({ user }) => {
  const { get } = useAxios();
  const params = useParams();

  const [errorMessage, setErrormessage] = useState("");
  const [creativesPortfolios, setCreativesPortfolios] = useState();
  const [isLoading, setIsloading] = useState(false);

  const handleFilterActivePortfolio = (portfolios) => {
    if (!portfolios) return;

    // portfolios = portfolios.map((portfolio) => {
    //   if (!portfolio?.creativesPortfolios) {
    //     portfolio.creativesPortfolios = [];
    //   }
    //   return portfolio;
    // });

    console.clear()
    console.log(portfolios)

    let activePortfolios = portfolios.filter((p) => p.status === "ACTIVE");

    return activePortfolios;
  };

  const handleGetPortfolios = async () => {
    setIsloading(true);

    const res = params?.user_id
      ? await get(`creatives/details/${params?.user_id}`)
      : user;

    res?.status === "ok" || (user && !params?.user_id)
      ? setCreativesPortfolios(
          handleFilterActivePortfolio(
            res?.creativeProfile?.creativesPortfolios
          ) || handleFilterActivePortfolio(res?.creativesPortfolios)
        )
      : setErrormessage(res?.errorMessage || "Failed to retrive portfolios");

    setIsloading(false);
  };

  useEffect(() => {
    !creativesPortfolios && handleGetPortfolios();
  }, [creativesPortfolios, params]);

  if (errorMessage) return <span>{errorMessage}</span>;

  if (!user || !user?.shareableProfileUrl) return <Loader loading={true} />;

  return (
    <>
      {user?.creativesEmploymentPosition ? (
        <p className="text-black fw-700 font-20 m-0">
          {user?.creativesEmploymentPosition?.positionTitle}

          {user?.creativesEmploymentPosition?.websiteCompany && (
            <>
              at{" "}
              <a
                className="text-black"
                href={user?.creativesEmploymentPosition?.websiteCompany || "#"}
              >
                {user?.creativesEmploymentPosition?.positionCompany}
              </a>
            </>
          )}
        </p>
      ) : !params.user_id ? (
        <p className="text-center text-muted fw-600 font-16 m-0 d-flex flex-column">
          <span>You do not have your info updated :(</span>
          <span>
            <Link to={"/profile/edit"} className="text-decoration-none">
              Complete
            </Link>{" "}
            your profile now !
          </span>
        </p>
      ) : (
        <p className="text-center text-muted fw-600 font-16 m-0 d-flex flex-column">
          <span>{user?.name} has not completed their profile</span>
        </p>
      )}

      <p className="font-16 mb-4">
        <a href="company_link?" className="text-48B7E7 text-decoration-none">
          {user?.currentCompanyWebsite?.details || ""}
        </a>
      </p>

      <div className="blockquote-wrapper">
        <div className="blockquote">
          <h5>{HTMLReactParser(user?.bio || "")}</h5>
          {/* <h6 className="d-flex">
            &mdash; {user?.name} |{" "}
            <br />
            <em>{user?.creativesEmploymentPosition?.positionTitle}</em>
          </h6> */}
        </div>
      </div>

      <hr className="my-5" />

      <Network user={user} className="d-block d-sm-none mb-4" />

      {creativesPortfolios?.length > 0 ? (
        <>
          <p className="text-black fw-700 line-height-normal font-20 mb-3">
            {user?.name}'s Portfolio
          </p>
          <div className="projects-container">
            {creativesPortfolios.map((portfolio, i) => (
              <ProjectCard key={portfolio.id} data={portfolio} />
            ))}
          </div>
        </>
      ) : (
        <p className="text-center text-muted fw-600 font-16 m-0 d-flex flex-column">
          <span>{user?.name} does not have any portfolio</span>
          {!params.user_id && (
            <span>
              <Link
                to={"/profile/edit"}
                state={{ tab: "projects" }}
                className="text-decoration-none"
              >
                Add
              </Link>{" "}
              your portfolio now !
            </span>
          )}
        </p>
      )}
    </>
  );
};

export default ProfileOverviewContent;
