import {
  GET_ALL_JOBS,
  BOOKMARK_SUCCESS,
  BOOKMARK_FAILURE,
  SELECTED_JOB_FAILURE,
  SELECTED_JOB_SUCCESS,
  APPLY_JOB_SUCCESS,
  APPLY_JOB_FAILURE,
  FILTER_DATA,
  FILTER_BY_JOB,
  SEARCH_QUERY,
  SET_ALL_JOBS,
  SET_PAGE_INDEX,
  FILTER_DROPDOWN_DATA,
} from "../../constants/action-types";
import { request } from "../../services/http-service";
import { successAlert, errorAlert } from "../../constants/helper";

export const getAllJobs = (route, allJobsObject, from) => {
  const action = from === "listing" ? GET_ALL_JOBS : FILTER_BY_JOB;

  let params = Object.keys(allJobsObject).filter((key) => {
    return allJobsObject[key] !== "" && allJobsObject[key] !== null;
  });

  const dataObject = {};

  params.forEach((param) => {
    dataObject[param] = allJobsObject[param];
  });

  return (dispatch) => {
    return request("jobs/all", "post", dataObject, true, dispatch).then(
      async (res) => {
        if (res.status === "ok") {
          dataObject.pageIndex = dataObject.pageIndex + 1;
          const hasNextPage =
            (await request("jobs/all", "post", dataObject, true, dispatch))
              ?.jobs?.length > 0;

          dispatch({ type: action, data: res, hasNextPage });
        } else {
          errorAlert(res.errorMessage);
        }
      }
    );
  };
};

export const setAllJobs = (payload) => async (dispatch) => {
  try {
    payload.pageIndex = payload.pageIndex + 1;
    const hasNextPage =
      (await request("jobs/all", "post", payload, true, dispatch))?.jobs
        ?.length > 0;

    dispatch({ type: SET_ALL_JOBS, data: payload, hasNextPage });
  } catch (error) {
    console.error(error);
  }
};

export const addToBookmark = (jobId) => {
  const bookmarkObject = { jobId: jobId };
  return (dispatch) => {
    return request(
      "jobs/bookmark/job",
      "POST",
      bookmarkObject,
      true,
      dispatch
    ).then((res) => {
      if (res.status === "ok") {
        res.response === "Job bookmark added" &&
          successAlert(
            "Bookmark added. To enhance your browsing experience, please check out our app where you can conveniently access and manage all your bookmarks",
            10000
          );
        dispatch({ type: BOOKMARK_SUCCESS });
      } else {
        errorAlert(res.errorMessage);
        dispatch({ type: BOOKMARK_FAILURE });
      }
    });
  };
};

export const getSelectedJobById = (jobId) => {
  const selectedJobObject = { jobId: jobId };
  return (dispatch) => {
    return request(
      "jobs/by-id",
      "POST",
      selectedJobObject,
      false,
      dispatch
    ).then(async (res) => {
      if (res.status === "ok") {
        try {
          const profileBannerUrl = (
            await request(
              `cced/user/details/${res.job.companyId}`,
              "get",
              "",
              false,
              dispatch
            )
          )?.employerProfile?.profileBannerUrl;

          if (profileBannerUrl) {
            res.job.profileBannerUrl = profileBannerUrl;
          }

          dispatch({ type: SELECTED_JOB_SUCCESS, data: res["job"] });
        } catch (error) {
          console.error(error);
        }
      } else {
        errorAlert(res.errorMessage);
        dispatch({ type: SELECTED_JOB_FAILURE });
      }
    });
  };
};

export const applyJob = (applyJobObj) => {
  return (dispatch) => {
    return request("jobs/apply/job", "POST", applyJobObj, true, dispatch).then(
      (res) => {
        if (res.status === "ok") {
          successAlert("Applied to Job Successfully");
          dispatch({ type: APPLY_JOB_SUCCESS });
        } else {
          errorAlert(res.errorMessage);
          dispatch({ type: APPLY_JOB_FAILURE });
        }
      }
    );
  };
};

export const getFilters = () => {
  return (dispatch) => {
    return request("utility/data/job/filters", "get", "", false, dispatch).then(
      (res) => {
        if (res) {
          dispatch({ type: FILTER_DATA, data: res });
        } else {
          errorAlert(res.errorMessage);
        }
      }
    );
  };
};

export const jobSearchQuery = (payload) => (dispatch) => {
  try {
    dispatch({ type: SEARCH_QUERY, data: payload });
  } catch (error) {
    console.error(error);
  }
};

export const setPageIndex = (payload) => (dispatch) => {
  try {
    return dispatch({ type: SET_PAGE_INDEX, data: payload });
  } catch (error) {
    console.error(error);
  }
};

export const getJobFilters = () => {
  try {
    return (dispatch) => {
      return request(
        "utility/data/job/dropdowns",
        "get",
        "",
        false,
        dispatch
      ).then(async (res) => {
        if (res) {
          const contries = await request(
            "utility/data/locations/countries",
            "get",
            "",
            false,
            dispatch
          );
          if (contries) res.countries = contries.locationsData;

          const nationalities = await request(
            "utility/data/locations/nationalities",
            "get",
            "",
            false,
            dispatch
          );
          if (nationalities) res.nationalities = nationalities.locationsData;

          dispatch({ type: FILTER_DROPDOWN_DATA, data: res });
        }
      });
    };
  } catch (error) {
    console.error(error);
  }
};
