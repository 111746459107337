import Datetime from "react-datetime";
import "./assets/style.css";

const DatePicker = ({ startDate, setStartDate }) => {
  return (
    // <ReactDatePicker
    //   selected={startDate}
    //   onChange={(date) => setStartDate(date)}
    //   showYearDropdown
    //   dateFormatCalendar="MMMM"
    //   yearDropdownItemNumber={15}
    //   scrollableMonthYearDropdown
    //   className="--form-properties w-100"
    // />
    <Datetime
      initialValue={startDate}
      value={startDate}
      onChange={(date) => setStartDate(date)}
      timeFormat={false}
      dateFormat="DD/MM/YYYY"
      isValidDate={(currentDate) => currentDate < new Date()}
      className="w-100"
    />
  );
};

export default DatePicker;
