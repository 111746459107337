import {
  COMMON_HEADERS,
  FORM_DATA_HEADERS,
  DEV_URL,
} from "../constants/config";
import { IS_LOADING } from "../constants/action-types";

const request = async (url, method, payload, auth, dispatch, dataType) => {
  const options = {
    method: method ? method : "GET",
    headers:
      dataType === "formData"
        ? { ...FORM_DATA_HEADERS }
        : { ...COMMON_HEADERS },
  };
  const token = localStorage.getItem("token2");
  if (auth && token) {
    options.headers["Authorization"] = "Bearer " + token;
  }
  if (payload) {
    options["body"] =
      dataType === "formData" ? payload : JSON.stringify(payload);
  }

  const apiUrl = `${DEV_URL}/${url}`;
  try {
    dispatch && dispatch({ type: IS_LOADING, payload: true });
    let res = await fetch(apiUrl, options);
    let data = await res.json();
    if (data.status === "ok") {
      dispatch && dispatch({ type: IS_LOADING, payload: false });
    } else {
      dispatch && dispatch({ type: IS_LOADING, payload: false });
    }
    return data;
  } catch (error) {
    dispatch && dispatch({ type: IS_LOADING, payload: false });
  }
};

export { request };
