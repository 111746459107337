import React, { useState, useEffect } from "react";

import Bookmark from "./Bookmark";
import { Col, Container, Row } from "react-bootstrap";

export default function Card(props) {
  const [selectedJobId, setSelectedJobId] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  // const [smShow, setSmShow] = useState(false);

  const selectedJob = (id) => {
    setSelectedJobId(id);
    props.selectedJob(id);
    // props.changeToMobileDisplay();
    if (width < 768) props.changeToMobileDisplay();

    // setSmShow(true)
  };

  return (
    <Container>
      <Row
        onClick={() => selectedJob(props.data.jobId)}
        data-id={props.data.jobId}
        className="job-card-div card p-3 mb-3 border-0 d-flex flex-row"
      >
        <Col
          xs={3}
          className="container-card-img-left h-auto d-flex align-items-center justify-content-center"
        >
          <img
            className="card-img-left rounded example-card-img-responsive"
            src={props?.data?.profileimageUrl || "/assets/icons/1.png"}
            onError={(e) => (e.target.src = "/assets/icons/1.png")}
            alt="Company Logo"
          />
        </Col>
        <Col xs={9} className="card-body flex-grow-1">
          <Col
            xs={12}
            className="d-flex align-items-start justify-content-start"
          >
            <Col className="flex-grow-1">
              <p className="card-title job-card-title font-20 m-0 fw-700">
                {props.data.title ?? "N/A"}
              </p>
            </Col>
            <Col xs={1}>
              <div className="job-card-bookmark">
                <Bookmark
                  isBookmarked={false}
                  jobId={props.data.jobId}
                ></Bookmark>
              </div>
            </Col>
          </Col>
          <p className="card-text font-16 fw-700 job-card-company mb-2">
            {props?.data?.companyName}
          </p>

          <p className="card-text font-12 fw-700 job-card-location m-0">
            {props?.data?.workModes?.join(", ") || props?.data?.workModes}
          </p>
          <p className="card-text font-12 fw-700 job-card-location m-0">
            {props.data.employmentTypes[0]} | {props.data.locations[0]}
          </p>
          <p className="card-text font-12 fw-700 job-card-salary m-0">
            {props.data.salaryRange}
          </p>
        </Col>
      </Row>
    </Container>
  );
}
