import "./Fonts.css";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

// import libs
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RevolvingDot } from "react-loader-spinner";
// import routes
import Home from "./routes/home";
import JobDetails from "./routes/job_details";
import Login from "./routes/login";
import Signup from "./routes/signup";
import ForgotPassword from "./routes/forgot_password";
import ResetPassword from "./routes/reset_password";
import Profile from "./pages/Profile";
import EditProfile from "./pages/Profile/EditProfile";
import Project from "./routes/project";
import EmployerProfile from "./routes/employer_profile";

// import components
import Layout from "./components/Layouts/Layout";
import ProfileLayout from "./pages/Profile/ProfileLayout";
import ProjectLayout from "./components/Layouts/ProjectLayout";
import EmployerProfileLayout from "./components/Layouts/EmployerProfileLayout";
import ScrollToTop from "./components/Includes/ScrollToTop";
import Toast from "./components/Toast/Toast";
import Loader from "./components/Commons/Loader";

// import utils
import history from "./utils/history";

import React, { useEffect } from "react";
import * as Actions from "./redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Onboarding from "./routes/onboarding";
import ViewProject from "./pages/Profile/Project/ViewProject";
import EditProject from "./pages/Profile/Project/EditProject";

function App({ actions, isLoggedIn, isLoading, user }) {
  useEffect(() => {
    if (isLoggedIn) {
      actions.getCurrentUser();
    }
  }, [isLoggedIn]);
  return (
    <Router history={history}>
      <ScrollToTop />
      <Loader loading={isLoading}></Loader>
      <Toast />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route element={<Layout />} path="/">
          <Route path="/" element={<Home data="home" />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route path="/jobs" element={<Home data="jobs" />} />
          <Route path="/job/:jobId" element={<JobDetails />} />
          <Route path="/profile" element={<Profile user={user} />} />
          <Route path="/profile/edit" element={<EditProfile user={user} />} />
          <Route path="/profile/edit/:project_id" element={<EditProfile user={user} />} />
          <Route path="/profile/:user_id" element={<Profile />} />
          <Route element={<ProjectLayout />} path="/project" />
          <Route path="/project/:project_id" element={<ViewProject />} />
          <Route element={<EmployerProfileLayout />} path="/employer" />
          <Route path="/employer/:employer_id" element={<EmployerProfile />} />
        </Route>
      </Routes>
    </Router>
  );
}

const mapStateToProps = (props) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  isLoading: props.loaderReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
