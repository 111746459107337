import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Network from "../../components/Profile/Network";
import ProfileOverviewContent from "./ProfileOverviewContent";
import * as Actions from "../../redux/actions/userAction";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";

import Loader from "../../components/Commons/Loader";
import useCreatives from "../../hooks/useCreatives";
import userReducer from "../../redux/reducers/userReducer";

const ProfileOverview = () => {
  const params = useParams();
  const user = useSelector(({ userReducer }) => userReducer.user);

  const { userData, errorMessage, handleGetCreativeProfile } = useCreatives(
    params?.user_id || user?.id
  );

  useEffect(() => {
    !userData && handleGetCreativeProfile(params?.user_id || user?.id);
  }, [params.user_id, userData, user]);

  if (errorMessage && !user)
    return (
      <div className="container-body error">
        <span className="text-muted font-24">
          {errorMessage || "Error Retrieving Creative's Profile"}
        </span>
      </div>
    );

  if (!userData && !user) return <Loader loading={true} />;

  return (
    <Container className={"mt-4"}>
      <Row>
        <Col sm={12} md={!params?.user_id ? 10 : 12} className="px-5">
          <ProfileOverviewContent user={userData || user} />
        </Col>
        {!params?.user_id && (
          <Col sm={12} md={2}>
            <Network user={userData || user} className="d-none d-sm-block" />
          </Col>
        )}
      </Row>
    </Container>
  );
};

const mapStateToProps = (props) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  isLoading: props.loaderReducer.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileOverview);
