import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Network from "../components/EmployerProfile/Network";
import ProfileSidebar from "../components/EmployerProfile/Sidebar";
import Content from "../components/EmployerProfile/Content";

import { useParams } from "react-router-dom";
import EmployerProfileLayout from "../components/Layouts/EmployerProfileLayout";
import { useEffect, useState } from "react";
import { useAxios } from "../api/useAxios";
import Loader from "../components/Commons/Loader";

export default function EmployerProfile() {
  const params = useParams();
  const { get } = useAxios();

  const [isLoading, setIsLoading] = useState(false);
  const [employerProfile, setEmployerProfile] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const handleGetEMployerProfile = async () => {
    try {
      setIsLoading(true);
      const employerData = await get(`cced/user/details/${params?.employer_id}`);

      employerData?.status === "ok" &&
        setEmployerProfile(employerData.employerProfile);

      setIsLoading(false);
    } catch (error) {
      setErrorMessage(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    params?.employer_id && !employerProfile && handleGetEMployerProfile();
  }, [params]);

  if (!employerProfile) return <Loader loading={isLoading} />;

  if (errorMessage)
    return (
      <div className="container-body error">
        <span className="text-muted font-24">
          {errorMessage || "Error Retrieving Employer's Profile"}
        </span>
      </div>
    );

  return (
    <EmployerProfileLayout coverImage={employerProfile?.profileBannerUrl}>
      <Container className={"mt-4"}>
        <Row>
          <Col sm={12} md={3}>
            <ProfileSidebar employerProfile={employerProfile}></ProfileSidebar>
          </Col>
          <Col sm={12} md={9} className="px-5">
            <Content employerProfile={employerProfile}></Content>
          </Col>
          {/* <Col sm={12} md={2}>
            <Network
              className="d-none d-sm-block"
              employerProfile={employerProfile}
            ></Network>
          </Col> */}
        </Row>
      </Container>
    </EmployerProfileLayout>
  );
}
