import {
  GET_ALL_JOBS,
  BOOKMARK_SUCCESS,
  SELECTED_JOB_FAILURE,
  SELECTED_JOB_SUCCESS,
  BOOKMARK_FAILURE,
  APPLY_JOB_FAILURE,
  APPLY_JOB_SUCCESS,
  FILTER_DATA,
  FILTER_BY_JOB,
  SEARCH_QUERY,
  SET_ALL_JOBS,
  SET_PAGE_INDEX,
  FILTER_DROPDOWN_DATA,
} from "../../constants/action-types";

const initialState = {
  prevJobListing: [],
  jobListing: [],
  selectedJob: {},
  status: "",
  filters: {},
  pageIndex: 0,
  pageSize: 9,
  totalCount: "",
  jobQuery: "",
  hasNextPage: null,
};

const jobReducer = (state = initialState, action) => {
  if (action.type === SET_ALL_JOBS) {
    return {
      ...state,
      prevJobListing: state.jobListing,
      jobListing: action.data,
      hasNextPage: action?.hasNextPage,
      status: SET_ALL_JOBS,
    };
  }
  if (action.type === GET_ALL_JOBS) {
    return {
      ...state,
      prevJobListing: state.jobListing,
      jobListing: action.data["jobs"],
      totalCount: action.data["totalCount"],
      hasNextPage: action?.hasNextPage,
      status: GET_ALL_JOBS,
    };
  }
  if (action.type === FILTER_BY_JOB) {
    return {
      ...state,
      prevJobListing: state.jobListing,
      jobListing: action.data["jobs"],
      totalCount: action.data["totalCount"],
      hasNextPage: action?.hasNextPage,
      status: FILTER_BY_JOB,
    };
  }
  if (action.type === BOOKMARK_SUCCESS) {
    return {
      ...state,
      status: BOOKMARK_SUCCESS,
    };
  }
  if (action.type === BOOKMARK_FAILURE) {
    return {
      ...state,
      status: BOOKMARK_FAILURE,
    };
  }
  if (action.type === SELECTED_JOB_SUCCESS) {
    return {
      ...state,
      selectedJob: action.data,
      status: SELECTED_JOB_SUCCESS,
    };
  }
  if (action.type === SELECTED_JOB_FAILURE) {
    return {
      ...state,
      status: SELECTED_JOB_FAILURE,
    };
  }
  if (action.type === APPLY_JOB_SUCCESS) {
    return {
      ...state,
      status: APPLY_JOB_SUCCESS,
    };
  }
  if (action.type === APPLY_JOB_FAILURE) {
    return {
      ...state,
      status: APPLY_JOB_FAILURE,
    };
  }
  if (action.type === FILTER_DATA) {
    return {
      ...state,
      filters: action.data,
    };
  }
  if (action.type === SEARCH_QUERY) {
    return {
      ...state,
      jobQuery: action.data,
    };
  }
  if (action.type === SET_PAGE_INDEX) {
    return {
      ...state,
      pageIndex: action.data,
    };
  }
  if (action.type === FILTER_DROPDOWN_DATA) {
    return {
      ...state,
      dropdownFilters: action.data,
      status: FILTER_DROPDOWN_DATA,
    };
  }
  return state;
};

export default jobReducer;
