import { GET_ALL_PHONE_PREFIXES } from "../../constants/action-types";
import { useAxios } from "../../api/useAxios";

export const getPhoneNumberPrefixes = (payload) => async (dispatch) => {
  const { get } = useAxios();
  const res = await get("utility/data/default", {});

  if (res.phoneNumbersData) {
    dispatch({
      type: GET_ALL_PHONE_PREFIXES,
      data: res.phoneNumbersData,
    });
  } else {
    return res.errorMessage;
  }
};
