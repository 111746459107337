import { useEffect, useState } from "react";
import { GetFileData, errorAlert } from "../../../constants/helper";

const usePortfolio = () => {
  const whiteListedImageTypes = ["jpeg", "jpg", "png", "ico"];

  const [portfolio, setPortfolio] = useState({
    title: "",
    description: "",
    fileData: "",
    fileName: "",
    contents: [],
  });

  const [gallery, setGallery] = useState([]);

  const handleImageTypeCheck = (fileType) => {
    if (!whiteListedImageTypes.includes(fileType)) {
      errorAlert("Image type not allowed");
      return false;
    } else return true;
  };

  const onAddGalleryImages = async (event, index) => {
    
    const images = event.target.files;

    if (images?.length < 1) return;

    const newImageUpload = [...(gallery?.content || []), ...images];
    newImageUpload.push(images);

    setGallery(newImageUpload.filter((img) => img.filePreview));
    let canUpload = false;

    let imageUrls = [];

    for (let i = 0; i < images.length; i++) {
      const readFile = await GetFileData(images[i]);

      const fileType = readFile.url.split(";")[0].split("/")[1];
      const isValidImage = handleImageTypeCheck(fileType);

      if (isValidImage) {
        imageUrls.push({
          filePreview: readFile.url,
          fileData: readFile.url.split(",")[1],
          fileName: readFile.filename,
          contentPosition: i,
          parentPosition: index,
        });

        canUpload = true;
      } else canUpload = false;
    }

    if (canUpload) {
      setGallery({
        ...gallery,
        [`field${index}`]: {
          content: imageUrls?.filter((img) => img.filePreview),
        },
      });
    }
  };

  const handleContentTypeChange = (index, value) => {
    setPortfolio((prevState) => {
      const contents = [...prevState.contents];
      contents[index].contentType = value;
      return { ...prevState, contents };
    });
  };

  const addContentItem = () => {
    setPortfolio((prevState) => ({
      ...prevState,
      contents: [...prevState.contents, {}],
    }));
  };

  return {
    portfolio,
    setPortfolio,
    handleContentTypeChange,
    addContentItem,
    gallery,
    onAddGalleryImages,
  };
};

export default usePortfolio;
