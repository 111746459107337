import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { faShareNodes } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import * as Actions from "../../redux/actions/jobAction";
import * as userActions from "../../redux/actions/userAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getBase64 } from "../../constants/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShareModal from "../Modals/ShareModal";

const Buttons = ({
  jobId,
  actions,
  props,
  isLogged,
  userActions,
  resumeData,
}) => {
  const navigate = useNavigate();
  const [resumes, setResumeListing] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [selectedCv, setSelectedCv] = useState();

  const updateCvSelection = (e, id) => {
    setSelectedCv(id);
    var elems = document.querySelectorAll(".resume-block");

    [].forEach.call(elems, function (el) {
      el.className = el.className.replace(/\bactive-resume\b/, "");
    });
    e.currentTarget.classList.toggle("active-resume");
  };
  useEffect(() => {
    if (isLogged) {
      userActions.getAllResumes();
    }
    setSelectedCv("");
  }, []);

  useEffect(() => {
    if (resumeData?.length) {
      setResumeListing(resumeData);
    }
  }, [resumeData]);

  const handleClose = (type) => {
    setShowShareModal(false);
    type === "share" ? setShowShareModal(false) : setShowApplyModal(false);
  };

  const handleShow = (type) => {
    type === "share" ? setShowShareModal(true) : setShowApplyModal(true);
  };

  const applyNow = (event, jobId) => {
    event.stopPropagation();
    if (isLogged) {
      handleShow("apply");
    } else {
      navigate(`/login?jobId=${jobId}`, { replace: true });
    }
  };
  const applyJob = (event, jobId) => {
    event.stopPropagation();
    const applyJobObj = {
      jobId: jobId,
      cvUploadId: selectedCv,
    };
    if (isLogged) {
      actions.applyJob(applyJobObj);
      setShowApplyModal(false);
    } else {
      navigate(`/login?jobId=${jobId}`, { replace: true });
    }
  };

  const onResumeUpload = async (event) => {
    const file = event.target.files[0];
    getBase64(event.target.files[0], (result) => {
      file["base64"] = result;

      if (result) {
        const uploadResume = {
          fileName: file.name,
          fileData: file["base64"].split(",")[1],
        };
        userActions.uploadResume(uploadResume);
      }
    });
  };

  return (
    <>
      <ShareModal
        title="Share Job"
        showShareModal={showShareModal}
        handleClose={handleClose}
        shareLink={`${process.env.REACT_APP_BASE_URI}/job/${
          props?.jobId || props
        }`}
      />

      <Modal
        className={"apply-now-modal"}
        show={showApplyModal}
        onHide={() => handleClose("apply")}
        centered
      >
        <Modal.Header className={"border-0 p-3"} closeButton>
          <Modal.Title className={"text-white font-24 "}>Apply Now</Modal.Title>
        </Modal.Header>
        <Modal.Body className={"p-3"}>
          <p className="text-white fw-700 text-16">
            Select a resume or manage them on your profile.
          </p>
          <div className="apply-now-modal-inner-content">
            <div className="apply-resumes-list">
              {resumes.map((resume, i) => {
                return (
                  <div key={i}>
                    <div
                      onClick={(e) => updateCvSelection(e, resume.uploadId)}
                      key={resume.uploadId}
                      className="resume-block mb-2 border border-1 border-dark p-2 text-14 fw-700 bg-d9d9d9 d-flex align-items-center text-center rounded justify-content-center"
                      data-id={resume.id}
                    >
                      {resume.fileName}.{resume.fileExtension}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <input
                className="d-none"
                onChange={onResumeUpload}
                type="file"
                id="upload-cv"
              />
              <label htmlFor="upload-cv" className="w-100">
                <span className="d-block btn border-1 p-1 ps-3 pe-3 me-2 blue-text border-dark fw-700 --variant-light">
                  Upload CV
                </span>
              </label>
              <button
                disabled={!selectedCv}
                onClick={(event) => applyJob(event, props.jobId)}
                className="w-100 btn border-1 p-1 ps-3 pe-3 text-white border-dark fw-700 --variant-dark"
                data-job-id={""}
              >
                Apply Now
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <button
        onClick={(event) => applyNow(event, props.jobId)}
        className="font-family-futuram btn border-1 border-accent p-1 ps-3 pe-3 me-2 font-16 text-white --variant-dark"
        data-job-id={""}
      >
        Apply Now
      </button>
      <button
        className="font-family-futuram btn border-1 border-accent p-1 ps-3 pe-3 font-16 --variant-light"
        data-job-id={""}
        onClick={() => handleShow("share")}
      >
        Share <FontAwesomeIcon icon={faShareNodes} />
      </button>
    </>
  );
};
const mapStateToProps = (props, parentProps) => ({
  props: parentProps,
  isLogged: props.userReducer.isLoggedIn,
  resumeData: props.userReducer.resumes,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Buttons);
