import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "../../redux/actions/userAction";
import * as JobActions from "../../redux/actions/jobAction";
import Card from "../job/EmployeeJobCard";
import Carousel from "react-bootstrap/Carousel";
import Network from "./Network";
import Loader from "../Commons/Loader";
const Content = ({
  employerProfile,
  isLogged,
  getSelectedJob,
  userActions,
  allJobs,
}) => {
  const selectedJob = (value) => {
    getSelectedJob(value);
  };

  useEffect(() => {
    if (isLogged) {
      userActions.getCurrentUser();
    }
  }, [isLogged]);

  if (!employerProfile?.id) return <Loader loading={true} />;

  return (
    <>
      <p className={"text-black fw-700 font-20 m-0"}>{employerProfile?.name}</p>
      <p className={"font-16 mb-4"}>
        <a
          href={employerProfile?.websiteUrl}
          target="_blank"
          className="text-48B7E7 text-decoration-none"
        >
          {employerProfile?.websiteUrl}
        </a>
      </p>
      <p className="text-black font-20 mb-5 font-family-futuram line-height-normal">
        {employerProfile?.bio}
      </p>

      {employerProfile?.galleryImagesUrls?.length > 0 && (
        <>
          <p className={"text-black font-20 mb-3 fw-700"}>Gallery</p>
          <Carousel className={"cc-carousel employer-gallery-slider mb-5"}>
            {employerProfile.galleryImagesUrls.map((slide, i) => {
              return (
                <Carousel.Item key={i}>
                  <img className="d-block w-100" src={slide} alt="" />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </>
      )}
    </>
  );
};

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  allJobs: props.jobReducer.jobListing,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  jobActions: bindActionCreators(JobActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
