import { useEffect, useState } from "react";
import { GetFileData, errorAlert } from "../../../constants/helper";

const useProfile = (user) => {
  const [profile, setProfile] = useState();
  const [profileImage, setProfileThumbnail] = useState("");
  const [headerImage, setHeaderThumbnail] = useState();
  const [profileImages, setProfileImages] = useState([]);
  const [thumbnailImages, setThumbnailImages] = useState([]);

  const whiteListedImageTypes = ["jpeg", "jpg", "png", "ico"];

  const handleImageTypeCheck = (fileType) => {
    if (!whiteListedImageTypes.includes(fileType)) {
      errorAlert("Image type not allowed");
      return false;
    } else return true;
  };

  const onProfileImageChange = async (e, clearErrors) => {
    clearErrors("profileImage");

    const image = e.target.files[0];
    const readFile = await GetFileData(image);

    const fileType = readFile.url.split(";")[0].split("/")[1];
    const isValidImage = handleImageTypeCheck(fileType);

    isValidImage &&
      setProfileThumbnail({
        filePreview: readFile.url,
        fileData: readFile.url.split(",")[1],
        fileName: readFile.filename,
      });
  };

  const onHeaderImageChange = async (e, clearErrors) => {
    clearErrors("headerImage");

    const image = e.target.files[0];
    const readFile = await GetFileData(image);

    const fileType = readFile.url.split(";")[0].split("/")[1];
    const isValidImage = handleImageTypeCheck(fileType);

    isValidImage &&
      setHeaderThumbnail({
        filePreview: readFile.url,
        fileData: readFile.url.split(",")[1],
        fileName: readFile.filename,
      });
  };

  const onAddProfileImages = async (event) => {
    const images = event.target.files;
    const newImageUpload = [...profileImages];
    newImageUpload.push(images);
    setProfileImages(newImageUpload);
    let canUpload = false;

    let imageUrls = [];

    for (let i = 0; i < images.length; i++) {
      const readFile = await GetFileData(images[i]);

      const fileType = readFile.url.split(";")[0].split("/")[1];
      const isValidImage = handleImageTypeCheck(fileType);

      if (isValidImage) {
        imageUrls.push({
          filePreview: readFile.url,
          fileData: readFile.url.split(",")[1],
          fileName: readFile.filename,
        });

        canUpload = true;
      } else canUpload = false;
    }

    canUpload && setThumbnailImages(imageUrls);
  };

  useEffect(() => {
    user && !profile?.username && setProfile(user);
  }, [user]);

  return {
    profile,
    setProfile,
    profileImage,
    onProfileImageChange,
    setProfileThumbnail,
    headerImage,
    onHeaderImageChange,
    setHeaderThumbnail,
    profileImages,
    onAddProfileImages,
    setProfileImages,
    thumbnailImages,
    setThumbnailImages,
  };
};
export default useProfile;
