import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const AccountInfoFields = ({
  user,
  register,
  profileOptions,
  selectedPhoneNumberPrefix,
  setSelectedPhoneNumberPrefix,
  phonePrefixes,
  profileImage,
  setProfileThumbnail,
  onProfileImageChange,
  errors,
  clearErrors,
}) => {
  return (
    <Row className="container-profile-account-info">
      <Col
        xs={12}
        md={3}
        className="d-flex justify-content-center align-items-center"
      >
        <input
          onChange={(e) => onProfileImageChange(e, clearErrors)}
          type="file"
          className="d-none"
          accept="image/*"
          id="profile-image"
        />
        <label
          id="profile-image-label"
          htmlFor="profile-image"
          className="w-100 my-3 d-flex align-items-center justify-content-center p-5 mb-3 font--inter-medium font-16 blue-text rounded blue-border --border-3px-dashed --cursor-pointer --bg-no-repeat"
          style={{
            backgroundImage: `url(${
              profileImage?.filePreview ||
              user?.profileImageUrl ||
              "/assets/icons/1.png"
            })`,
            height: 80,
            width: 80,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          {!profileImage && (
            <div className="d-flex flex-column align-items-center">
              <img
                src="/assets/icons/camera.png"
                alt="camera"
                className="mb-2"
                id="profile-image-src"
              />

              <span className="text-center">Profile Picture</span>
            </div>
          )}
        </label>
        <div className="text-danger">
          {errors?.profileImage && errors.profileImage.message}
        </div>
      </Col>
      <Col className="flex-grow-1">
        <Col>
          <label htmlFor="form-input" className="form-label">
            Name <span className="text-danger">*</span>
          </label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="name"
              {...register("name", profileOptions.name)}
            />
          </div>
          <small className="text-danger">
            {errors?.name && errors.name.message}
          </small>
        </Col>
        <Col>
          <label htmlFor="form-input" className="form-label">
            Email Address <span className="text-danger">*</span>
          </label>
          <div className="input-group">
            <input
              disabled
              value={user?.email}
              type="email"
              className="form-control"
              id="email-address"
              placeholder="Email Address"
            />
          </div>
        </Col>

        <Col>
          <label htmlFor="form-input" className="form-label">
            Phone Number <span className="text-danger">*</span>
          </label>
          <div className="input-group d-flex">
            <Controller
              control={useForm().control}
              render={({ field: { onChange, value } }) => (
                <select
                  name="phoneNumberDataId"
                  defaultValue={selectedPhoneNumberPrefix}
                  value={selectedPhoneNumberPrefix}
                  className="bg-danger select-user-profile-phone-prefix bg-transparent"
                  onChange={({ currentTarget: { value } }) => {
                    setSelectedPhoneNumberPrefix(value);
                    onChange(value);
                  }}
                >
                  {phonePrefixes?.length > 0 &&
                    phonePrefixes
                      .sort((a, b) => a.callingCode - b.callingCode)
                      .map((phonePrefix) => (
                        <option key={phonePrefix.id} value={phonePrefix.id}>
                          {phonePrefix.callingCode}
                        </option>
                      ))}
                </select>
              )}
              {...register(
                "phoneNumberDataId",
                profileOptions.phoneNumberDataId
              )}
            />
            <input
              type="text"
              className="form-control"
              id="phone-number"
              placeholder="Phone Number"
              defaultValue={user?.phoneNumber}
              {...register("phoneNumber", profileOptions.phoneNumber)}
            />
          </div>
          <small className="text-danger">
            {errors?.phoneNumber && errors.phoneNumber.message}
          </small>
        </Col>
      </Col>
    </Row>
  );
};

export default AccountInfoFields;
