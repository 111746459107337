import { combineReducers } from "redux";
import userReducer from "./userReducer";
import jobReducer from "./jobReducer";
import loaderReducer from "./loaderReducer";
import utilsReducer from "./utilsReducer";

const rootReducer = combineReducers({
  userReducer,
  jobReducer,
  loaderReducer,
  utilsReducer,
});

export default rootReducer;
