import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ViewProject from "../../pages/Profile/Project/ViewProject";

const ProjectLayout = ({ children }) => {
  return (
    <>
      <div>
        <Outlet />
      </div>
      {children}
    </>
  );
};

export default ProjectLayout;
