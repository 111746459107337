import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";

const ShareModal = ({ title, showShareModal, handleClose, shareLink }) => {
  const shareableLinkRef = useRef(null);
  const [isCopying, setIsCopying] = useState(false);
  const [key, setKey] = useState("copy");

  const handleCopyShareableLink = () => {
    setIsCopying(true);
    navigator.clipboard.writeText(shareableLinkRef.current.value);

    setTimeout(() => {
      setIsCopying(false);
    }, 1500);
  };

  return (
    <Modal
      className={"share-modal"}
      show={showShareModal}
      onHide={() => handleClose("share")}
      centered
    >
      <Modal.Header className={"border-0 p-3"} closeButton>
        <Modal.Title className={"text-white font-24 "}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={"p-3"}>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="copy" title="Copy">
            <div className={"share-modal-inner-content"}>
              <p className={"text-white font-16 "}>
                To share the link, press the copy button.
              </p>
              <div className={"form-group"}>
                <input
                  ref={shareableLinkRef}
                  className={"share-link me-2"}
                  readOnly
                  value={shareLink}
                />
                <button
                  className={`btn ${
                    isCopying ? "bg-success" : "bg-blue"
                  } border-0 p-1 ps-3 pe-3 text-white me-2 w-25`}
                  onClick={() => handleCopyShareableLink()}
                >
                  {isCopying ? <FontAwesomeIcon icon={faCheck} /> : "Copy"}
                </button>
              </div>
            </div>
          </Tab>
          {/* <Tab eventKey="download" title="Download">
            <div className={"share-modal-inner-content"}>
              <p className="text-white font-16 fw-700">
                Download the Cult Creative app on the App Store or Google Play.
              </p>
              <div className="text-center">
                <a className="me-3" href="apple" target="_blank">
                  <img
                    className="w-150px"
                    src="/assets/images/download-apple.png"
                    alt="logo"
                  />
                </a>
                <a href="google" target="_blank">
                  <img
                    className="w-150px"
                    src="/assets/images/download-google.png"
                    alt="logo"
                  />
                </a>
              </div>
            </div>
          </Tab> */}
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
