import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Download from "../Commons/Download";

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions/userAction";
import { LOGOUT_SUCCESS } from "../../constants/action-types";
import Logout from "../Commons/Logout";
import { Col, Row } from "react-bootstrap";

const MenuMobile = ({ actions, isLogged, user, status }) => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    setIsLogin(isLogged);
  }, [isLogged]);

  useEffect(() => {
    if (!isLogged && status === LOGOUT_SUCCESS) {
      actions.emptyStatus();
      navigate("/", { replace: true });
    }
  }, [status]);

  const logoutFromMobileMenu = () => {
    actions.logoutAction();
  };
  return (
    <>
      {isLogin ? (
        <LoggedInItems
          logoutFromMobileMenu={logoutFromMobileMenu}
          data={user}
        />
      ) : (
        <LoggedOutItems />
      )}
    </>
  );
};

function LoggedInItems(props) {
  const [show, setShow] = useState(false);
  const logoutFromMobile = () => {
    props.logoutFromMobileMenu();
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Link
        to={"/jobs"}
        className={
          "font-16 line-height-normal text-center minWidth-100px fw-700 me-2 text-black text-decoration-none p-2 pe-3 ps-3 rounded-pill border border-dark text-dark border-3"
        }
      >
        Jobs
      </Link>
      <Button
        variant="link"
        className={"text-black p-0 font-26"}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>

      <Offcanvas
        className={"mobile-sidebar-menu"}
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Body className={"p-4 ps-3 pe-3"}>
          <Link
            to={"/"}
            className="font-16 d-flex align-items-center fw-700 mb-4 pb-2 me-2 text-black text-decoration-none text-dark"
          >
            <Row className="brand m-0 p-0 d-flex justify-content-center align-items-center w-100">
              <Col xs={2} className="p-0 m-0">
                <img
                  className={"me-3"}
                  src="/assets/images/logos/logo-c.png"
                  alt="mobile logo"
                  style={{ width: "35px" }}
                />
              </Col>
              <Col className="d-flex justify-content-start align-items-center flex-grow-1 m-0 p-0">
                <div className="d-flex flex-column">
                  <span className="brand-mobile-header">Cult Creative</span>
                  <span className="brand-mobile-text">
                    Where all creatives get their dream job.
                  </span>
                </div>
              </Col>
            </Row>
          </Link>

          <a
            href="/profile"
            className={
              "font-16 d-flex align-items-center fw-700 mb-2 pb-2 me-2 text-black text-decoration-none text-dark border-0 border-bottom border-dark"
            }
          >
            <img
              className="--object-fit-cover-center rounded-circle"
              src={props?.data?.profileImageUrl || "/assets/icons/1.png"}
              onError={(e) => (e.target.src = "/assets/icons/1.png")}
              alt="mobile logo"
              width={32}
              height={32}
            />
            <div className="py-0 px-3 m-0 ">
              <span>My Profile</span>
            </div>
          </a>
          {/* <Download
            placement="mobile-menu"
            text={"Download the App"}
            className={
              "font-16 d-flex align-items-center fw-700 mb-2 pb-2 me-2 text-black text-decoration-none text-dark border-0 border-bottom border-dark"
            }
          ></Download> */}
          <Logout placement="mobile-menu" />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function LoggedOutItems() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Link
        to={"/signup"}
        style={{ fontSize: "14px", minWidth: "100px", textAlign: "center" }}
        className={
          "fw-700 me-2 text-black text-decoration-none p-2 pe-3 ps-3 rounded-pill border border-dark text-dark border-3"
        }
      >
        Join Now
      </Link>
      <Button
        variant="link"
        className={"text-black p-0 font-26"}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>

      <Offcanvas
        className="mobile-sidebar-menu"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Body className={"p-4 ps-3 pe-3"}>
          <Link
            to={"/"}
            className="font-16 d-flex align-items-center fw-700 mb-4 pb-2 me-2 text-black text-decoration-none text-dark"
          >
            <Row className="brand m-0 p-0 d-flex justify-content-center align-items-center w-100">
              <Col xs={2} className="p-0 m-0">
                <img
                  className={"me-3"}
                  src="/assets/images/logos/logo-c.png"
                  alt="mobile logo"
                  style={{ width: "35px" }}
                />
              </Col>
              <Col className="d-flex justify-content-start align-items-center flex-grow-1 m-0 p-0">
                <div className="d-flex flex-column">
                  <span className="brand-mobile-header">Cult Creative</span>
                  <span className="brand-mobile-text">
                    Where all creatives get their dream job.
                  </span>
                </div>
              </Col>
            </Row>
          </Link>

          <Link
            to="/login"
            className="font-16 d-flex align-items-center fw-700 mb-2 pb-2 me-2 text-black text-decoration-none text-dark border-0 border-bottom border-dark px-2"
          >
            <Row className="brand m-0 p-0 d-flex justify-content-center align-items-center w-100">
              <Col xs={2} className="p-0 m-0">
                <img
                  className={"me-3"}
                  src="/assets/icons/user.png"
                  alt="mobile logo"
                  style={{ width: "26px" }}
                />
              </Col>
              <Col className="d-flex justify-content-start align-items-center flex-grow-1 m-0 p-0">
                <div className="d-flex flex-column">
                  <span className="brand-mobile-header">Login</span>
                </div>
              </Col>
            </Row>
          </Link>

          <Link
            to={process.env.REACT_APP_CCEMPLOYER_URI}
            target="_blank"
            className="font-16 d-flex align-items-center fw-700 mb-2 pb-2 me-2 text-black text-decoration-none text-dark border-0 border-bottom border-dark px-2"
          >
            <Row className="brand m-0 p-0 d-flex justify-content-center align-items-center w-100">
              <Col xs={2} className="p-0 m-0">
                <img
                  className={"me-3"}
                  src="/assets/icons/hiring.svg"
                  alt="mobile logo"
                  style={{ width: "26px" }}
                />
              </Col>
              <Col className="d-flex justify-content-start align-items-center flex-grow-1 m-0 p-0">
                <div className="d-flex flex-column">
                  <span className="brand-mobile-header">I'm Hiring</span>
                </div>
              </Col>
            </Row>
          </Link>

          {/* <Row className="brand m-0 p-0 d-flex justify-content-center align-items-center w-100">
            <Col className="d-flex justify-content-start align-items-center flex-grow-1 m-0 p-0">
              <Download
                placement="mobile-menu"
                text="Download the App"
                className="font-16 d-flex align-items-start fw-700 ml-0 pl-0 mb-2 pb-2 me-2 text-black text-decoration-none text-dark border-0 border-bottom border-dark"
              />
            </Col>
          </Row> */}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  status: props.userReducer.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuMobile);
