import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "../../redux/actions/userAction";
import { errorAlert, getBase64, successAlert } from "../../constants/helper";
import Modal from "react-bootstrap/Modal";
import { useAxios } from "../../api/useAxios";

const Resumes = ({ userActions, userData, isLogged, resumeData }) => {
  const { get, post } = useAxios();
  const dispatch = useDispatch();
  const [uploadFile, setUploadFile] = useState({});
  const [resumes, setResumeListing] = useState([]);
  const [selectedResume, setSelectedResume] = useState();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (uploadId) => {
    setSelectedResume(uploadId);
    setShow(true);
  };

  const uploadResume = () => {
    const uploadResume = {
      fileName: uploadFile.name,
      fileData: uploadFile["base64"].split(",")[1],
    };

    userActions.uploadResume(uploadResume);
  };

  const onResumeUpload = async (event) => {
    const file = event.target.files[0];
    setUploadFile(file);
    getBase64(event.target.files[0], (result) => {
      file["base64"] = result;
      setUploadFile(file);
    });
  };

  const openUrlNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const confirmDelete = async () => {
    const res = await post("uploads/delete/cv", { uploadId: selectedResume });

    if (res?.status === "ok") {
      const cvListRes = await get("creatives/cvs");

      if (cvListRes?.status === "ok") {
        successAlert("Resume deleted");
        setResumeListing(cvListRes.creativeCvs);
        handleClose();
      } else {
        errorAlert(cvListRes?.errorMessage || "Failed to retrieve CVS");
        handleClose();
      }
    } else {
      errorAlert(res?.errorMessage || "Resume deleted");
      handleClose();
    }
  };

  useEffect(() => {
    userActions.getAllResumes(true);
  }, []);

  useEffect(() => {
    if (resumeData?.length) {
      setResumeListing(resumeData);
    }
  }, [resumeData]);

  return (
    <>
      <Modal
        className="confirm-delete-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="border-0 p-3 mb-5" closeButton></Modal.Header>
        <Modal.Body className="p-3">
          <p className="text-white text-center font-24 fw-700 m-0 mb-5">
            Confirm Delete CV?
          </p>
          <div className="text-center mb-5">
            <button
              onClick={confirmDelete}
              className="btn blue-text border-accent text-decoration-none p-1 ps-3 pe-3 font-18 fw-700 me-3 bg-white"
            >
              Confirm
            </button>
            <button
              onClick={handleClose}
              className="btn border-1 border-accent p-1 ps-3 pe-3 text-white font-18 fw-700 --variant-dark"
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <p className="fw-700 text-24">Your Current Resumes</p>
      <form id="upload-resume-form" className="mb-4">
        <p className="fw-700 text-24 text-center">Select a resume below.</p>
        <div className="mb-3">
          <label
            htmlFor="resume"
            className="resume-upload-block text-center p-5 w-100 mx-auto d-block --cursor-pointer"
          >
            <img src="/assets/icons/upload.png" alt="upload" />
            <input
              onChange={onResumeUpload}
              type="file"
              className="form-control d-none"
              id="resume"
            />
            <p>{uploadFile && uploadFile.name ? uploadFile.name : ""}</p>
          </label>
        </div>
        <button
          disabled={!uploadFile.name}
          onClick={uploadResume}
          type="button"
          className="w-25 d-block mx-auto btn bg-blue border-1 p-1 ps-3 pe-3 text-white font-14 border-dark fw-700"
        >
          Upload
        </button>
      </form>

      {resumes.length > 0 ? (
        <div className="resumes-list">
          {resumes.map((resume, i) => {
            return (
              <div key={i}>
                <div
                  onClick={(event) => openUrlNewTab(resume.cvUrl)}
                  key={resume.uploadId}
                  className="resume-block mb-2 border border-1 border-dark p-2 text-14 fw-700 w-100 bg-d9d9d9 d-flex align-items-center text-center rounded justify-content-center"
                  data-id={resume.uploadId}
                >
                  {resume.fileName}.{resume.fileExtension}
                </div>
                <button
                  className="w-100 btn bg-blue border-1 p-1 ps-3 pe-3 text-white border-dark fw-700"
                  type="button"
                  onClick={() => handleShow(resume.uploadId)}
                >
                  Delete
                </button>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="empty-cv-main p-4 text-center">
          <div className="empty-cv-div">
            <img src="/assets/icons/no-cv.png" className="mb-3" alt="empty" />
            <p className="blue-text font-18 fw-700">No resumes uploaded yet</p>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (props, parentProps) => ({
  isLogged: props.userReducer.isLoggedIn,
  userData: props.userReducer.user,
  resumeData: props.userReducer.resumes,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Resumes);
