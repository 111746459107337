import { useParams, useNavigate } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import JobDetailCard from '../components/job/Details';
import Container from 'react-bootstrap/Container';
import React, { useEffect, useState } from "react";
import * as Actions from "../redux/actions/jobAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const JobDetails  = ({ actions, isLoggedIn, status, selectedJob }) => {
    const [selectedJobData, setSelectedJobData] = useState({});
    const navigate = useNavigate();
    let params = useParams();
    let jobId = params.jobId;
    useEffect(() => {
        // if(isLoggedIn){
            actions.getSelectedJobById(jobId);
        // }
        // else{
        //     navigate('/login', { replace: true });
        // }
       
  }, []);
    useEffect(() => {
    setSelectedJobData(selectedJob);
    }, [selectedJob]);
    return (
        <>
        <Container className={"home-listing-container mt-4"}>
				<Row>
					{/* <div className={"d-none d-md-block"}> */}
					<div className={"d-md-block"}>
						<JobDetailCard data={selectedJobData} />
					</div>
				</Row>
			</Container>
        </>
    );
}

const mapStateToProps = (props) => ({
    isLoggedIn: props.userReducer.isLoggedIn,
    status: props.userReducer.status,
    selectedJob: props.jobReducer.selectedJob,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);