import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import "./assets/style.scss";

const ProgressBar = ({ percentage }) => {
  const animationControls = useAnimation();

  React.useEffect(() => {
    animationControls.start({ width: `${percentage}%` });
  }, [percentage, animationControls]);

  const isPercentageZero = percentage === 0;

  return (
    <div className="progress-bar">
      <motion.div
        className="fill"
        initial={{ width: 0 }}
        animate={animationControls}
        transition={{ duration: 0.5 }}
        style={{
          backgroundColor: percentage > 99 ? "#12BC0A" : "#e8c063",
        }}
      >
        {!isPercentageZero && (
          <div
            className={`content text-${percentage > 99 ? "white" : "black"}`}
          >
            {percentage}% Profile Completed
          </div>
        )}
      </motion.div>
      {isPercentageZero && (
        <div className={`content text-${percentage > 99 ? "white" : "black"}`}>
          {percentage}% Profile Completed
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
