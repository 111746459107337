import { useState } from "react";
import {
  FacebookEmbed,
  YouTubeEmbed,
  TikTokEmbed,
  TwitterEmbed,
  LinkedInEmbed,
  InstagramEmbed,
  PinterestEmbed,
} from "react-social-media-embed";

const ContentVideo = ({ content }) => {
  let videoUrl = content?.content;
  let urlDomain;

  try {
    urlDomain = new URL(videoUrl);
  } catch (error) {
    urlDomain = videoUrl;
  }

  const platforms = {
    youtube: {
      domain: ["youtube.com", "youtu.be", "www.youtube.com"],
      embed: <YouTubeEmbed url={videoUrl} width="100%" height="400px" />,
    },
    facebook: {
      domain: ["facebook.com", "fb.watch"],
      embed: <FacebookEmbed url={videoUrl} width="100%" height="400px" />,
    },
    instagram: {
      domain: ["instagram.com", "www.instagram.com"],
      embed: <InstagramEmbed url={videoUrl} width="100%" height="auto" />,
    },
    linkedIn: {
      domain: ["linkedin.com", "www.linkedin.com"],
      embed: <LinkedInEmbed url={videoUrl} width="100%" height="400px" />,
    },
    pinterest: {
      domain: ["pinterest.co.uk", "www.pinterest.co.uk"],
      embed: <PinterestEmbed url={videoUrl} width="100%" height="400px" />,
    },
    tikTok: {
      domain: ["tiktok.com", "www.tiktok.com"],
      embed: <TikTokEmbed url={videoUrl} width="100%" height="auto" />,
    },
    twitter: {
      domain: ["twitter.com", "www.twitter.com"],
      embed: <TwitterEmbed url={videoUrl} width="100%" height="400px" />,
    },
  };

  const videoType = Object.keys(platforms).filter((type) =>
    platforms[type].domain.includes(urlDomain)
  )[0];

  if (!videoUrl || !platforms[videoType]) return <></>;

  return (
    <div className="d-flex justify-content-center align-items-center">
      {platforms[videoType].embed}
    </div>
  );
};

export default ContentVideo;
