import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../../redux/actions/userAction";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EditProfileDetails from "./EditProfileDetails";
import Resumes from "../../../components/Profile/Resumes";
import Download from "../../../components/Commons/Download";
import Logout from "../../../components/Commons/Logout";
import Project from "../../../routes/project";
import ProfileLayout from "../ProfileLayout";
import { Button, Col, Container, Row } from "react-bootstrap";
import ProfileSidebar from "../../../components/Profile/ProfileSidebar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ProgressBar from "../../../components/framerMotion/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { useAxios } from "../../../api/useAxios";
import { errorAlert, successAlert } from "../../../constants/helper";

const Profile = ({ user, actions }) => {
  const { post } = useAxios();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [key, setKey] = useState(params?.project_id ? "projects" : "details");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [viewType, setViewType] = useState(params?.project_id ? "Edit" : "Add");
  const [orangeTick, setOrangeTick] = useState({
    tick1: 0,
    tick2: 0,
    tick3: 0,
    tick4: 0,
  });

  const setTab = (k) => setKey(k);

  const handleDeletePortfolio = async () => {
    if (!params?.project_id) return;

    const res = await post("creatives/portfolio/delete", {
      id: params.project_id,
    });

    if (res?.status === "ok") {
      successAlert(res?.response || "Creatives portfolio deleted");
      await actions.getCurrentUser();
      navigate("/profile", { replace: true });
    } else {
      errorAlert(res?.errorMessage || "Failed to delte portfolio");
    }
  };

  useEffect(() => {
    location?.state?.tab && setKey(location.state.tab);
  }, [location]);

  return (
    <ProfileLayout>
      <ConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        title="Confirm to delete this portfolio ?"
        children={
          <div className="text-center d-flex justify-content-end align-items-end">
            <button
              className="btn bg-blue border-1 border-accent p-1 ps-3 pe-3 text-white font-18 fw-700"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              className="mx-3 btn blue-text  text-decoration-none p-1 ps-3 pe-3 font-18 fw-700 me-3 bg-danger text-white"
              onClick={() => handleDeletePortfolio()}
            >
              Confirm
            </button>
          </div>
        }
      />
      <Container className={"mt-4"}>
        <Row>
          <Col sm={12} md={3}>
            <ProfileSidebar user={user} />
          </Col>
          <Col sm={12} md={9} className="px-5">
            <Download text="Portfolio" className="d-none openDownloadLink" />
            <div className="edit-profile-main">
              <p className="font-26 fw-700">Edit Profile</p>
              <div className="position-relative edit-profile-container p-4 border border border-dark border-1 rounded">
                <div
                  className={
                    "position-absolute end-0 text-secondary text-decoration-none fw-700 me-3"
                  }
                />
                <div className="progress-bar-sticky">
                  {orangeTick && key !== "projects" && (
                    <ProgressBar
                      percentage={Object.values(orangeTick).reduce(
                        (acc, curr) => acc + curr,
                        0
                      )}
                    />
                  )}
                </div>
                {key === "projects" && viewType === "Edit" && (
                  <div className="--position-top-right">
                    <Button
                      className="btn-portfolio-delete border-1 border-danger"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Delete Portfolio
                    </Button>
                  </div>
                )}

                <Tabs
                  id="edit-profile-tabs"
                  activeKey={key}
                  onSelect={(k) => setTab(k)}
                  className="mb-3 edit-profile-tabs"
                >
                  <Tab eventKey="details" title="Details">
                    <EditProfileDetails
                      orangeTick={orangeTick}
                      setOrangeTick={setOrangeTick}
                    />
                  </Tab>
                  <Tab eventKey="resumes" title="Resumes">
                    <Resumes />
                  </Tab>
                  <Tab eventKey="projects" title="Portfolio">
                    <Project viewType={viewType} setViewType={setViewType} />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
};

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  status: props.userReducer.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
