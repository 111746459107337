import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import * as Actions from "../redux/actions/userAction";
import * as utilsActions from "../redux/actions/utilsAction";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { errorAlert } from "../constants/helper";

const Onboarding = ({ actions, isLogged, user, phonePrefixes, auth }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPhoneNumberPrefix, setSelectedPhoneNumberPrefix] =
    useState("1");

  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSignup = async (data) => {
    if (!data.name) {
      data.name = data?.firstName + " " + data?.lastName;
    }

    data.phoneNumberDataId = selectedPhoneNumberPrefix;
    data.isSocialLogin = true;

    const signupStatus = await actions.signup(data);

    // const signupStatus =
    //   location.state.type === "signup"
    //     ? await actions.signup(data)
    //     : await actions.editProfile(data);

    // if (signupStatus === "ok") {
    //   const loginStatus = await actions.loginUser(data);
    //   loginStatus && navigate("/", { replace: true });
    // } else {
    //   setErrorMessage(signupStatus);
    // }

    if (signupStatus === "ok") {
      await actions.loginUserSocial({
        email: data.email,
        password: data.password,
        token: auth.token,
        localId: auth.localId,
        providerId: auth.providerId,
      });

      const loginRes = await actions.editProfile(data);

      if (loginRes?.status === "ok") {
        navigate("/dashboard", { replace: true });
      } else {
        return errorAlert(
          loginRes?.errorMessage || "Failed to authenticate user"
        );
      }
    }
  };

  const signupOptions = {
    firstName: { required: "First Name is required" },
    lastName: { required: "Last Name is required" },
    username: { required: "Username is required" },
    email: { required: "Email is required" },
    phoneNumber: { required: "Phone Number is required" },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
    confirmPassword: {
      required: "Confirm Password is required",
      validate: (val) => {
        if (watch("password") != val) {
          return "Your passwords do no match";
        }
      },
    },
  };

  useEffect(() => {
    if (location?.state?.data?.name) {
      reset({
        firstName: location.state.data.name.split(" ")[0],
        lastName: location.state.data.name.split(" ").slice(1).join(" "),
        username: location.state.data.username,
        email: location.state.data.email,
        phoneNumber: location.state.data.phoneNumber.slice(
          3,
          location.state.data.phoneNumber.length
        ),
      });
    }
  }, [user]);

  useEffect(() => {
    if (isLogged) navigate("/", { replace: true });
    if (!isLogged && !location?.state?.data)
      navigate("/login", { replace: true });
  }, [isLogged, location.state]);

  useEffect(() => {
    user?.phoneNumber &&
      phonePrefixes?.length > 0 &&
      setSelectedPhoneNumberPrefix(
        () =>
          phonePrefixes.find((prefix) =>
            user.phoneNumber.startsWith(prefix.callingCode)
          ).id
      );
  }, [user, phonePrefixes]);

  useEffect(() => {
    dispatch(utilsActions.getPhoneNumberPrefixes());
  }, []);

  return (
    <form
      onSubmit={handleSubmit(handleSignup)}
      className="ls-form signup-form w-100 px-3 py-2"
    >
      <h3 className={"text-center my-5 fw-700"}>Personal Information</h3>

      <div className="form-group mb-3 row">
        <Col>
          <input
            name="firstName"
            type="text"
            className="form-control"
            id="first_name"
            placeholder="First Name *"
            {...register("firstName", signupOptions.firstName)}
          />
          <small className="text-danger">
            {errors?.firstName && errors.firstName.message}
          </small>
        </Col>
        <Col>
          <input
            type="text"
            className="form-control"
            id="last_name"
            placeholder="Last Name *"
            name="lastName"
            {...register("lastName", signupOptions.lastName)}
          />
          <small className="text-danger">
            {errors?.lastName && errors.lastName.message}
          </small>
        </Col>
      </div>
      <div className="form-group mb-3">
        <input
          type="text"
          className="form-control"
          id="username"
          placeholder="Username *"
          name="username"
          {...register("username", signupOptions.username)}
        />
        <small className="text-danger">
          {errors?.username && errors.username.message}
        </small>
      </div>
      <div className="form-group mb-3">
        <input
          type="email"
          className="form-control"
          id="email"
          placeholder="Email *"
          name="email"
          {...register("email", signupOptions.email)}
        />
        <small className="text-danger">
          {errors?.email && errors.email.message}
        </small>
      </div>
      <div className="form-group mb-3">
        <input
          type="password"
          className="form-control"
          id="password"
          placeholder="Password *"
          name="password"
          {...register("password", signupOptions.password)}
        />
        <small className="text-danger">
          {errors?.password && errors.password.message}
        </small>
      </div>
      <div className="form-group mb-3">
        <input
          type="password"
          className="form-control"
          id="confirm_password"
          placeholder="Confirm Password *"
          name="confirmPassword"
          {...register("confirmPassword", signupOptions.confirmPassword)}
        />
        <small className="text-danger">
          {errors?.confirmPassword && errors.confirmPassword.message}
        </small>
      </div>
      <div className="form-group mb-3 d-flex">
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <select
              name="phoneNumberDataId"
              defaultValue={selectedPhoneNumberPrefix}
              value={selectedPhoneNumberPrefix}
              className="select-user-profile-phone-prefix bg-transparent"
              onChange={({ currentTarget: { value } }) => {
                setSelectedPhoneNumberPrefix(value);
                onChange(value);
              }}
            >
              {phonePrefixes?.length > 0 &&
                phonePrefixes
                  .sort((a, b) => a.callingCode - b.callingCode)
                  .map((phonePrefix) => (
                    <option key={phonePrefix.id} value={phonePrefix.id}>
                      {phonePrefix.callingCode}
                    </option>
                  ))}
            </select>
          )}
          {...register("phoneNumberDataId", signupOptions.phoneNumberDataId)}
        />
        <input
          type="text"
          className="form-control"
          id="phone_number"
          placeholder="Phone Number *"
          name="phoneNumber"
          {...register("phoneNumber", signupOptions.phoneNumber)}
        />
        <small className="text-danger">
          {errors?.phoneNumber && errors.phoneNumber.message}
        </small>
      </div>

      <Row className="m-0 mb-1 justify-content-center">
        <span className="text-danger --text-16 text-center pt-2 pb-3">
          {errorMessage}
        </span>
        <button
          className="p-2 ps-3 pe-3 bg-blue text-white border-1 border-accent border-radius-20 fw-700"
          type="submit"
        >
          Update Personal Info
        </button>
      </Row>
    </form>
  );
};

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  phonePrefixes: props.utilsReducer.phoneNumberPrefixes,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  utilsActions: bindActionCreators(utilsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
