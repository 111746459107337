import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProfileSidebar from "../../components/Profile/ProfileSidebar";
import ProfileLayout from "./ProfileLayout";
import ProfileOverview from "./ProfileOverview";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../components/Commons/Loader";
import "./assets/style.scss";
import { useAxios } from "../../api/useAxios";

const Profile = () => {
  const { get } = useAxios();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(({ userReducer }) => userReducer.isLoggedIn);
  const user = useSelector(({ userReducer }) => userReducer.user);
  const [userId, setUserId] = useState();
  const params = useParams();

  const [publicUser, setPublicUser] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const handleGetUser = async () => {
    try {
      const publicUserData = await get(`creatives/details/${userId}`);

      if (publicUserData?.status === "ok") {
        publicUserData.creativeProfile.name =
          publicUserData?.creativeProfile?.name || "Guest";

        setPublicUser(publicUserData.creativeProfile);
      } else {
        setErrorMessage(
          publicUserData?.errorMessage || "Failed to retrieve user profile"
        );
      }
    } catch (error) {
      setErrorMessage("Failed to retrieve user profile");
      console.error(error);
    }
  };

  useEffect(() => {
    if (!params?.user_id && !params?.project_id && !isLoggedIn) {
      navigate("/jobs", { replace: true });
    }
  }, [params, isLoggedIn]);

  useEffect(() => {
    if (params?.user_id) {
      setUserId(params?.user_id);
    } else {
      setUserId(user?.id);
    }

    userId && handleGetUser();
  }, [user, userId, params]);

  if (!publicUser && !errorMessage) return <Loader loading={true} />;

  if (errorMessage)
    return (
      <div className="text-muted font-32 d-flex justify-content-center align-items-center --height-full-body">
        {errorMessage}
      </div>
    );

  return (
    <ProfileLayout coverImage={publicUser?.profileBannerUrl}>
      <Container className={"mt-4"}>
        <Row>
          <Col sm={12} md={3}>
            <ProfileSidebar user={publicUser} />
          </Col>
          <Col sm={12} md={9}>
            <ProfileOverview user={publicUser} />
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
};

export default Profile;
