import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions/userAction";
import { LOGOUT_SUCCESS } from "../../constants/action-types";
import Logout from "../Commons/Logout";
import { Dropdown } from "react-bootstrap";

const MenuDesktop = ({ actions, isLogged, user, status }) => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setIsLogin(isLogged);
  }, [isLogged]);
  useEffect(() => {
    if (!isLogged && status === LOGOUT_SUCCESS) {
      actions.emptyStatus();
      navigate("/", { replace: true });
    }
  }, [status]);

  function LoggedInItems(props) {
    return (
      <>
        <Link to={"/jobs"} className={"text-black text-decoration-none"}>
          Jobs
        </Link>
        <Link
          to={process.env.REACT_APP_CCEMPLOYER_URI}
          target="_blank"
          className={"text-black text-decoration-none"}
        >
          I'm Hiring
        </Link>

        <Dropdown
          onMouseLeave={() => setShowDropdown(false)}
          onMouseOver={() => setShowDropdown(true)}
        >
          <Dropdown.Toggle className="bg-transparent bg-transparent p-0 m-0 border-0">
            <Link to={"/profile"} className={"text-black text-decoration-none"}>
              <img
                id="header-profile-image"
                src={props?.data?.profileImageUrl || "/assets/icons/1.png"}
                onError={(e) => (e.currentTarget.src = "/assets/icons/1.png")}
                alt={props?.user?.name || "Guest"}
                className={"desktop-menu-avatar avatar-img"}
              />
            </Link>
          </Dropdown.Toggle>

          <Dropdown.Menu show={showDropdown} className="--transform-top-55">
            <Dropdown.Item href="/profile" className="text-center">
              <span className="--font-FuturaBookBT-16-700">Profile</span>
            </Dropdown.Item>
            <Dropdown.Item className="p-0 m-0 --hover-bg-grey">
              <Logout classes="--font-FuturaBookBT-16-700 --hover-bg-grey" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  function LoggedOutItems() {
    return (
      <>
        <Link to={"/jobs"} className={"text-black text-decoration-none"}>
          Jobs
        </Link>
        <Link
          to={process.env.REACT_APP_CCEMPLOYER_URI}
          target="_blank"
          className={"text-black text-decoration-none"}
        >
          I'm Hiring
        </Link>
        <Link
          to={"/signup"}
          className={
            "text-black text-decoration-none p-2 pe-3 ps-3 rounded-pill border border-dark text-dark border-3 bg-transparent"
          }
        >
          Sign Up
        </Link>
        {/* <Link to={"/login"} className={"text-black text-decoration-none"}>
          Login
        </Link> */}
      </>
    );
  }
  return (
    <div
      className={
        "desktop-main-menu font-20 fw-700 d-flex align-items-center justify-content-end flex-row"
      }
    >
      {isLogin ? <LoggedInItems data={user} /> : <LoggedOutItems />}
    </div>
  );
};

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  status: props.userReducer.status,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuDesktop);
