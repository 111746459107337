import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Carousel } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useAxios } from "../../../../api/useAxios";
import { useSelector } from "react-redux";
import ContentVideo from "./ContentVideo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";

const ViewProject = () => {
  const [user, setUser] = useState();

  const loggedInUer = useSelector(({ userReducer }) => userReducer.user);
  const params = useParams();
  const { get } = useAxios();

  const [project, setProject] = useState();

  const handleGetProject = async () => {
    try {
      const isValidProjectId = /^[0-9]+$/.test(params?.project_id);

      if (!isValidProjectId) return;

      const response = await get(`creatives/portfolio/${params?.project_id}`);
      if (response.status === "ok") {
        response?.creativesPortfolio?.contents?.sort(
          (a, b) => a.contentPosition - b.contentPosition
        );

        setProject(response.creativesPortfolio);

        const userRes = await get(
          `creatives/details/${response?.creativesPortfolio?.userId}`
        );

        userRes?.status === "ok" && setUser(userRes.creativeProfile);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    !project && params?.project_id && handleGetProject();
  }, [params]);

  if (!project || !user) return <></>;

  return (
    <Container className="container-project w3-content">
      <header className="w3-display-container w3-wide" id="home">
        <div className="image-overlay" />

        <Image
          id="profile-portfolio-banner"
          className="w3-image"
          src={project?.imageUrl}
          alt={project?.title}
          placeholder="/assets/images/placeholders/cc-logo.png"
          fluid
        />

        <div className="w3-display-left w-100 text-center d-flex flex-column justify-content-center align-items-center text-white">
          {loggedInUer?.id === project?.userId && (
            <Link
              to={`/profile/edit/${params?.project_id}`}
              className="project-edit-btn text-black border-0 text-decoration-none rounded"
            >
              Edit
            </Link>
          )}
        </div>
      </header>

      <Row className="w3-row w3-border m-0 p-0 mt-5 pt-3 project">
        <Col lg={2} className="m-0 p-0 pt-4 pb-2 d-flex align-items-start">
          <Link
            to={loggedInUer.id === user.id ? "/profile" : `/profile/${user.id}`}
            className="text-decoration-none text-black d-flex align-items-center"
          >
            <FontAwesomeIcon icon={faArrowLeftLong} />
            <div className="project_main__back mx-3">Back to Profile</div>
          </Link>
        </Col>
        <Col lg={9} className="m-0 p-0 d-flex flex-column align-items-start">
          <div className="d-flex flex-column">
            <span className="project_main__title">{project?.title}</span>
            <span className="project_main__description">
              {project?.description}
            </span>
          </div>

          <Col
            xs={12}
            className="d-flex align-items-center project_profile__avatar"
          >
            <Image
              src={user?.profileImageUrl || "/assets/icons/1.png"}
              alt={user?.name}
            />
            <div className="d-flex flex-column align-items-start justify-content-center">
              <span className="mx-3 project_profile__name">{user?.name}</span>
              <span className="mx-3 project_profile__bio">{user?.bio}</span>
            </div>
          </Col>

          <hr />

          <Col xs={12} className="d-flex flex-column project_content">
            {project?.contents?.map((content) => (
              <div className="d-flex flex-column project-content">
                {content.contentType === "VIDEO" && (
                  <ContentVideo content={content} />
                )}

                {content.contentType === "IMAGE" && content?.imageUrl && (
                  <div>
                    <Image
                      src={content?.imageUrl}
                      alt={content.content}
                      fluid
                      className="w-100"
                    />
                    <span className="container-caption">
                      {content?.content}
                    </span>
                  </div>
                )}

                {content.contentType === "IMAGES" && (
                  <div>
                    <Carousel
                      className={"cc-carousel portfolio-gallery-slider mb-5"}
                    >
                      {content?.contents?.map((slide, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <img
                              className="d-block w-100 portfolio-carousel-item"
                              src={slide?.imageUrl}
                              alt="Portfolio Gallery"
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                )}

                {content.contentType === "TEXT" && (
                  <>
                    <span className="project_content_description">
                      {content?.content}
                    </span>
                    <span className="container-caption">
                      {content?.externalUrl}
                    </span>
                  </>
                )}
              </div>
            )) || <></>}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewProject;
