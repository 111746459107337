import { GetFileData, errorAlert } from "../constants/helper";

const whiteListedImageTypes = ["jpeg", "jpg", "png", "ico"];

const handleImageTypeCheck = (fileType) => {
  if (!whiteListedImageTypes.includes(fileType)) {
    errorAlert("Image type not allowed");
    return false;
  } else return true;
};

export const imgToBase64 = async (e) => {
  const image = e.target.files[0];
  const readFile = await GetFileData(image);

  const fileType = readFile.url.split(";")[0].split("/")[1];
  const isValidImage = handleImageTypeCheck(fileType);

  if (isValidImage) {
    return {
      filePreview: readFile.url,
      fileData: readFile.url.split(",")[1],
      fileName: readFile.filename,
    };
  } else {
    errorAlert("Image type not allowed");
  }
};

export const imageUrlToBase64 = async (imageUrl) => {
  try {
    const options = {
      method: "GET",
      headers: {
        Accept: "image/*",
      },
      mode: "cors",
    };

    const response = await fetch(imageUrl, options);

    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        const fileType = response.headers.get("content-type").split("/")[1];
        const isValidImage = handleImageTypeCheck(fileType);

        if (isValidImage) {
          resolve({
            filePreview: reader.result,
            fileData: base64String,
            fileName: "image." + fileType,
          });
        } else {
          reject(new Error("Image type not allowed"));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting URL to Base64:", error);
    throw error;
  }
};

export const imgUrlToBase64 = async (imageUrl) => {
  return fetch(imageUrl)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    )
    .then((dataUrl) => {
      const base64 = dataUrl.split(",")[1];
      return base64;
    })
    .catch((error) => {
      console.error("Error:", error);
      return null;
    });
};
