import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_FAILURE,
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  EMPTY_STATUS,
  GET_ALL_RESUMES,
  UPLOAD_RESUME_FAILURE,
  UPLOAD_RESUME_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
} from "../../constants/action-types";
import { request } from "../../services/http-service";
import { successAlert, errorAlert } from "../../constants/helper";
import store from "../store";

export const getCurrentUser = (data) => {
  try {
    return (dispatch) => {
      const storeState = store.getState();

      if (storeState?.userReducer?.user?.loginType) {
        dispatch({
          type: AUTHENTICATE_USER_SUCCESS,
          data: storeState.userReducer.user,
        });
      } else {
        return request(
          "creatives/details/logged-in",
          "GET",
          "",
          true,
          dispatch
        ).then((res) => {
          if (res.status === "ok") {
            dispatch({
              type: AUTHENTICATE_USER_SUCCESS,
              data: res.creativeProfile,
            });
          } else {
            dispatch({
              type: AUTHENTICATE_USER_FAILURE,
              payload: "User created Failure",
            });
            localStorage.removeItem("token2");
          }
        });
      }
    };
  } catch (error) {
    throw error;
  }
};

export const getUserByID = () => {
  return (dispatch) => {
    return request(
      "creatives/details/logged-in",
      "POST",
      "",
      true,
      dispatch
    ).then((res) => {
      if (res.status === "ok") {
        dispatch({ type: AUTHENTICATE_USER_SUCCESS, data: res["user"] });
      } else {
        dispatch({
          type: AUTHENTICATE_USER_FAILURE,
          payload: "User created Failure",
        });
        localStorage.removeItem("token2");
      }
    });
  };
};

export const loginUser = (loginData) => {
  return async (dispatch) => {
    try {
      const loginResponse = await request(
        "user-auth/authenticate",
        "post",
        loginData,
        false,
        dispatch
      );
      if (loginResponse && loginResponse.token) {
        localStorage.setItem("token2", loginResponse.token);
        dispatch({ type: LOGIN_SUCCESS });
        dispatch(getCurrentUser());
        successAlert("User login Successfully");
        return loginResponse.status;
      } else {
        errorAlert(loginResponse.errorMessage);
        dispatch({ type: LOGIN_FAILURE });
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FAILURE, payload: error });
    }
  };
};

export const loginUserSocial = (loginData, hideAlert) => {
  return async (dispatch) => {
    try {
      if (loginData?.token) {
        const loginResponse = await request(
          `user-auth/authenticate/${loginData.providerId}`,
          "post",
          loginData,
          false,
          dispatch
        );

        if (loginResponse.status === "ok") {
          localStorage.setItem("token2", loginResponse.token);
          dispatch({ type: LOGIN_SUCCESS });
          const getUserData = await dispatch(getCurrentUser());

          if (getUserData.errorMessage) return getUserData;

          !hideAlert && successAlert("User login Successfully");

          return loginResponse.status;
        } else {
          return loginResponse;
        }
      }
    } catch (error) {
      console.error(error);
      dispatch({ type: LOGIN_FAILURE, payload: error });
    }
  };
};

export const signup = (signupData, hideAlert) => {
  return async (dispatch) => {
    return request("user/signup", "POST", signupData, true, dispatch).then(
      async (res) => {
        if (res.status === "ok") {
          dispatch({ type: SIGNUP_USER_SUCCESS, data: res["user"] });
          !hideAlert && successAlert("Account created");

          const loginResponse = await request(
            "user-auth/authenticate",
            "post",
            signupData,
            false,
            dispatch
          );
          if (loginResponse && loginResponse.token) {
            localStorage.setItem("token2", loginResponse.token);
            dispatch({ type: LOGIN_SUCCESS });
            dispatch(getCurrentUser());
            successAlert("Account created Successfully");
            return loginResponse.status;
          }
        } else {
          dispatch({
            type: SIGNUP_USER_FAILURE,
            payload: "User signup Failure",
          });
          localStorage.removeItem("token2");
          errorAlert("Failed to create account");

          return res.errorMessage;
        }
      }
    );
  };
};

export const logoutAction = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    localStorage.removeItem("token2");
    successAlert("User logout Successfully");
  };
};

export const emptyStatus = () => {
  return (dispatch) => {
    dispatch({
      type: EMPTY_STATUS,
    });
  };
};

export const uploadResume = (uploadResumeObj) => {
  return (dispatch) => {
    return request(
      "uploads/upload/cv",
      "POST",
      uploadResumeObj,
      true,
      dispatch
    ).then((res) => {
      if (res.status === "ok") {
        dispatch(getAllResumes());
        successAlert("Resume Upload Successfully");
        dispatch({ type: UPLOAD_RESUME_SUCCESS });
      } else {
        errorAlert(res.errorMessage);
        dispatch({ type: UPLOAD_RESUME_FAILURE });
      }
    });
  };
};

export const getAllResumes = (disabledAlert) => {
  return (dispatch) => {
    return request("creatives/cvs", "get", "", true, dispatch).then((res) => {
      if (res.status === "ok") {
        dispatch({ type: GET_ALL_RESUMES, data: res["creativeCvs"] });
      } else {
        !disabledAlert && errorAlert(res.errorMessage);
      }
    });
  };
};

export const editProfile = (data, disabledAlert) => {
  return (dispatch) => {
    return request("user/details/edit", "POST", data, true, dispatch).then(
      (res) => {
        if (res.status === "ok") {
          !disabledAlert && successAlert("Profile Edit Successfully");
          return { status: "ok" };
        } else {
          !disabledAlert && errorAlert(res.errorMessage);
          return res.errorMessage;
        }
      }
    );
  };
};
