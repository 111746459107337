import Spinner from "react-bootstrap/Spinner";

export default function Loader(props) {
  return <>{props.loading ? <LoaderDiv></LoaderDiv> : null}</>;
}

function LoaderDiv() {
  return (
    <div id="loaderBG" className="loading">
      <div className="loader">
        <Spinner animation="border" variant="dark" />
      </div>
    </div>
  );
}
