import { Outlet, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import ProjectLayout from "../../components/Layouts/ProjectLayout";

const ProfileLayout = ({
  children,
  coverImage,
  isBannerHidden,
  connectionProfileImageUrl,
}) => {
  const location = useLocation();

  const profileBannerUrl = useSelector(
    ({ userReducer }) => userReducer?.user?.profileBannerUrl
  );

  return (
    <ProjectLayout>
      {!isBannerHidden && (
        <Container fluid className={"p-0"}>
          <Row className="m-0">
            <Col className={"p-0"}>
              <img
                id="profile-banner"
                className={"w-100"}
                src={
                  coverImage ||
                  profileBannerUrl ||
                  "/assets/images/placeholders/profile-banner.png"
                }
                onError={(e) =>
                  (e.currentTarget.src =
                    "/assets/images/placeholders/profile-banner.png")
                }
                alt="Profile Banner"
                height={286}
              />
            </Col>
          </Row>
        </Container>
      )}

      <div>
        <Outlet />
      </div>
      {children}
    </ProjectLayout>
  );
};

export default ProfileLayout;
