import { Link, useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Bookmark from "./Bookmark";
import Buttons from "./Buttons";
// import DetailsModal from "./DetailsModal";
import Offcanvas from "react-bootstrap/Offcanvas";
import * as Actions from "../../redux/actions/jobAction";
import parse from "html-react-parser";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const JobDetailCard = (props) => {
  const location = useLocation();

  const contentStyle = !location?.pathname?.includes("/job/")
    ? { height: "calc(100vh - 40px)", top: "20px" }
    : {};

  const handleClose = () => {
    props.changeToMobileDisplay();
  };

  const displayJobDetails = (props) => {
    let job = props.data;

    return (
      <>
        <Offcanvas
          className={"job-details-sidebar"}
          placement="end"
          show={props.showCanvas}
          onHide={handleClose}
        >
          <Offcanvas.Body className={"p-4 ps-3 pe-3"}>
            <button
              type="button"
              className="btn-close close-job-details-offcanvas mb-3"
              onClick={handleClose}
            >
              <img alt="Back" src="/assets/icons/back-arrow.svg" /> Back to list
            </button>
            <div className="job-details-card-mobile card border-0 position-relative">
              <div className="jdm-header-div d-flex flex-row align-items-center mb-4">
                <img
                  className="card-img-left rounded me-3"
                  src={job?.profileimageUrl || "/assets/icons/1.png"}
                  alt="Company Logo"
                  style={{
                    height: "75px",
                    width: "75px",
                    objectFit: "cover",
                    objectPosition: "center center",
                  }}
                />
                <div className="jdm-header-details">
                  <p className={"text-black fw-700 font-20 mb-0"}>
                    {job?.title}
                  </p>
                  <p className={"text-black fw-700 font-14 m-0"}>
                    Job Posted by{" "}
                    <Link
                      // to={"/employer/" + job?.companyId}
                      to={"#"}
                      className={"text-black"}
                    >
                      {job?.companyName}
                    </Link>
                  </p>
                  <p className={"text-black fw-700 font-12 mb-0"}>
                    {moment(job?.publishedDate, "YYYY-MM-DD HH:mm A").format(
                      "DD-MM-YYYY hh:mm A"
                    )}{" "}
                    | {job?.applicantsCount} Applicants
                  </p>
                  <p className={"text-black fw-700 font-12 m-0"}>
                    {job?.country}
                  </p>
                  <Bookmark
                    isBookmarked={props.data?.bookmarked}
                    jobId={props.data.jobId}
                  ></Bookmark>
                </div>
              </div>
              <div className={"btns-rows-mobile"}>
                <Buttons jobId={props.data.jobId} />
              </div>
              <div className={"mt-3 mb-3"}>
                <table className={"table border-0"}>
                  <tbody>
                    <tr>
                      <th className={"border-0"}>
                        <h5 className="fw-700">Employment Type</h5>
                        <p
                          className={
                            "p-1 ps-3 pe-3 bg-orange d-inline-block border-0 rounded"
                          }
                        >
                          {job?.employmentTypes?.join(", ") ||
                            job?.employmentTypes}
                        </p>
                      </th>
                      <th className={"border-0"}>
                        <h5 className="fw-700">Education Level</h5>
                        <p className="p-1 ps-3 pe-3 bg-yellow d-inline-block border-0 rounded">
                          {job?.educationLevels?.join(", ") ||
                            job?.educationLevels}
                        </p>
                      </th>
                    </tr>
                    <tr>
                      <th className={"border-0"}>
                        <h5 className="fw-700">Years of Experience</h5>
                        <p className="p-1 ps-3 pe-3 bg-light-blue d-inline-block border-0 rounded">
                          {job?.experienceLevels
                            ? job.experienceLevels
                                ?.join(", ")
                                ?.split("_")
                                ?.map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1).toLowerCase()
                                )
                                ?.join(" ")
                            : ""}
                        </p>
                      </th>
                      <th className={"border-0"}>
                        <h5 className="fw-700">Salary Range</h5>
                        <p className={""}>{job?.salaryRange}</p>
                        <h5 className="fw-700">Working Days</h5>
                        <p className={""}>
                          {job?.workingDays?.join(", ") || job?.workingDays}
                        </p>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <div className={"role-card-mobile mb-4"}>
                  {job?.description && (
                    <>
                      <p className={"mb-2 font-20 fw-700"}>The Role</p>
                      <p className={"mb-4 font-16 font-family-futuram"}>
                        {parse(job?.description || "")}
                      </p>
                    </>
                  )}

                  {job?.duties && (
                    <>
                      <p className={"mb-2 font-20 fw-700"}>Responsibilities</p>
                      {parse(job?.duties || "")}
                    </>
                  )}
                </div>

                {job?.benefits && (
                  <>
                    <p className={"mb-2 font-20 fw-700"}>Perks</p>
                    <p className={"mb-4 font-16 font-family-futuram"}>
                      {parse(job?.benefits) || ""}
                    </p>
                  </>
                )}

                {job?.companyOverview && (
                  <>
                    <p className={"mb-2 font-20 fw-700"}>Company Overview</p>
                    <p className={"mb-5 font-16 font-family-futuram"}>
                      {job?.companyOverview}
                    </p>
                  </>
                )}

                <div className={"btns-rows-mobile"}>
                  <Buttons jobId={props.data.jobId} />
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <div className="jd-banner-div">
          {props?.selectedJob?.profileBannerUrl ? (
            <div
              className="jd-card-banner"
              style={{
                backgroundImage: `url(${encodeURI(
                  props.selectedJob.profileBannerUrl
                )})`,
              }}
            />
          ) : (
            <div
              className="jd-card-banner"
              style={{
                backgroundImage: `url("/assets/images/placeholders/11.jpg")`,
              }}
            />
          )}
          <div className="jd-card-img-overlay">
            <div className="top-row">
              <Row>
                <Col xs={11}>
                  <p className={"text-white font-42 font-family-futuram mb-0"}>
                    {job?.title}
                  </p>
                  <p className={"text-white font-16 font-family-futuram"}>
                    Job Posted by{" "}
                    <Link
                      to={"/employer/" + job?.companyId}
                      // to={"#"}
                      className={"text-white"}
                    >
                      {job?.companyName}
                    </Link>
                  </p>
                </Col>
                <Bookmark
                  placement="details"
                  isBookmarked={props.data?.bookmarked}
                  jobId={props.data.jobId}
                ></Bookmark>
              </Row>
            </div>
            <div className="bottom-row">
              <Row className={"align-items-center"}>
                <Col sm={6}>
                  <div
                    className={"text-white mb-0 font-family-futuram font-20"}
                  >
                    <Col xs={12}>
                      {moment(job?.publishedDate, "YYYY-MM-DD HH:mm A").format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </Col>
                    <Col xs={12}>{job?.applicantsCount} Applicants</Col>
                  </div>
                  <p className={"text-white m-0 font-family-futuram font-14"}>
                    {job?.location}
                  </p>
                </Col>
                <Col sm={6} className={"text-end"}>
                  <Buttons jobId={props.data.jobId} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className={"job-details-container mt-3 mb-3 p-3 ps-4 pe-4"}>
          <table className={"table table-list border-0"}>
            <tbody>
              <tr>
                <th className={"border-0 p-0 w-50"}>
                  <p className="fw-700 font-24">Employment Type</p>
                  <p className="p-1 ps-3 pe-3 bg-orange d-inline-block border-0 rounded font-20 text-white">
                    {job?.employmentTypes?.join(", ") || job?.employmentTypes}
                  </p>
                </th>
                <th className={"border-0 p-0 w-50"}>
                  <p className="fw-700 font-24">Salary Range</p>
                  <p className={"font-20"}>{job?.salaryRange}</p>
                </th>
              </tr>
              <tr>
                <th className={"border-0 p-0 w-50"}>
                  <p className="fw-700 font-24">Education Level</p>
                  <p className="p-1 ps-3 pe-3 bg-yellow d-inline-block border-0 rounded font-20">
                    {job?.educationLevels?.join(", ") || job?.educationLevels}
                  </p>
                </th>
                <th className={"border-0 p-0 w-50"}>
                  <p className="fw-700 font-24">Working Days</p>
                  <p className={"font-20"}>
                    {job?.workingDays?.join(", ") || job?.workingDays}
                  </p>
                </th>
              </tr>
              <tr>
                <th className={"border-0 p-0 w-50 "}>
                  <p className="fw-700 font-24">Years of Experience</p>
                  <p className="p-1 ps-3 pe-3 bg-light-blue d-inline-block border-0 rounded font-20">
                    {job?.experienceLevels
                      ? job.experienceLevels
                          ?.join(", ")
                          ?.split("_")
                          ?.map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          ?.join(" ")
                      : ""}
                  </p>
                </th>
                <th className={"border-0 p-0 w-50"}>
                  <p className="fw-700 font-24">Work Mode</p>
                  <p className={"font-20"}>
                    {job?.workModes?.join(", ") || job?.workModes}
                  </p>
                </th>
              </tr>
            </tbody>
          </table>
          <div className={".card role-card border-0 border-radius-20 mb-5"}>
            <div className={"card-body"}>
              {job?.description && (
                <>
                  <p className={"mb-2 fw-700 font-24"}>The Role</p>
                  <p className={"mb-4 font-18 font-family-futuram"}>
                    {parse(job?.description || "")}
                  </p>
                </>
              )}

              {job?.duties && (
                <>
                  <p className={"mb-2 fw-700 font-24"}>Responsibilities</p>
                  <p className={"mb-4 font-18 font-family-futuram"}>
                    {parse(job?.duties || "")}
                  </p>
                </>
              )}
            </div>
          </div>

          {job?.benefits && (
            <>
              <p className={"mb-2 fw-700 font-24"}>Perks</p>
              <p className={"mb-4 font-18 font-family-futuram"}>
                {parse(job?.benefits) || ""}
              </p>
            </>
          )}

          <p className={"mb-2 fw-700 font-24"}>Company Overview</p>
          <p className={"mb-5 font-18 font-family-futuram"}>
            {job?.companyOverview}
          </p>
          <Buttons jobId={props.data.jobId} />
        </div>
      </>
    );
  };
  return (
    <>
      {/* <DetailsModal smShow={smShow} setSmShow={setSmShow} /> */}
      <div
        className="job-details-card card border-0 position-sticky overflow-auto"
        style={contentStyle}
      >
        {props.data && props.data?.jobId ? displayJobDetails(props) : null}
      </div>
    </>
  );
};

const mapStateToProps = (props) => ({
  selectedJob: props.jobReducer.selectedJob,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobDetailCard);
