import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions/userAction";
import NwCard from "./NwCard";
import Download from "../Commons/Download";

const Network = ({ actions, isLogged, classes }) => {
  return <React.Fragment>{<NetworkPeople classes={classes} />}</React.Fragment>;
};

function NetworkPeople(props) {
  const network_peoples = [
    {
      name: "Company 1",
      description: "Information & Design",
      image: "/assets/icons/1.png",
    },
    {
      name: "Company 2",
      description: "Ecommerce",
      image: "/assets/icons/2.png",
    },
    {
      name: "Company 3",
      description: "Testing 123",
      image: "/assets/icons/1.png",
    },
  ];
  return (
    <>
      <div className={props.classes}>
        <div
          className={
            "d-flex d-md-block align-items-center justify-content-between mb-3"
          }
        >
          <p className={"font-20 fw-700 m-0"}>Other Jobs</p>
          <Download
            text="View all"
            className="btn bg-transparent border-0 p-1 ps-3 pe-3 text-black border-dark fw-700 d-inline-block d-md-none"
          ></Download>
        </div>
        <div
          className={
            "network-container d-flex d-md-block justify-content-between"
          }
        >
          {network_peoples.map((nwp, i) => (
            <NwCard key={i} data={nwp}></NwCard>
          ))}
          <Download
            text="Show more"
            className="w-100 btn bg-transparent border-1 p-1 ps-3 pe-3 text-black border-dark d-none d-md-block font-12"
          ></Download>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (props, parentProps) => ({
  isLogged: props.userReducer.isLoggedIn,
  classes: parentProps.className,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Network);
