import {
  Button,
  Carousel,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import usePortfolio from "../hooks/usePortfolio";
import { imgToBase64, imgUrlToBase64 } from "../../../utils/imgToBase64";
import { useEffect, useState } from "react";
import * as UserActions from "../../../redux/actions/userAction";
import { errorAlert, successAlert } from "../../../constants/helper";
import { useAxios } from "../../../api/useAxios";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFileText,
  faImage,
  faImages,
  faPlayCircle,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import { motion } from "framer-motion";
import { verifyEmbedVideoUrl } from "./helper";

const EditProject = ({ userActions }) => {
  const params = useParams();
  const { get, post } = useAxios();
  const navigate = useNavigate();

  const [isUpdating, setIsUpdating] = useState(false);
  const [contents, setContents] = useState([]);
  const [coverImage, setCoverImage] = useState([]);
  const [contentImage, setContentImage] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeMediaButton, setActiveMediaButton] = useState(null);

  const { portfolio, setPortfolio, gallery, onAddGalleryImages } =
    usePortfolio();

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsUpdating(true);
    data.fileName = "portfolio";

    if (coverImage?.fileData) {
      data.fileData = coverImage.fileData;
    }
    // data.fileData =
    //   coverImage?.fileData || (await imgUrlToBase64(portfolio?.imageUrl));

    // const existingImage = portfolio?.contents
    //   ? await Promise.all(
    //       portfolio?.contents
    //         ?.filter((image) => image?.contentType === "IMAGE")
    //         .map(async (image) => {
    //           return {
    //             contentType: "IMAGE",
    //             contentPosition: image?.contentPosition,
    //             content: image?.content,
    //             externalUrl: image?.externalUrl,
    //             fileData: await imgUrlToBase64(image?.imageUrl),
    //           };
    //         })
    //     )
    //   : [];

    const existingImage = [];

    const newimage = contentImage.map((image) => {
      return {
        contentType: "IMAGE",
        contentPosition: Number(image.index),
        content: data[`imageContent_${image.index}`],
        externalUrl: "",
        fileData: image.fileData,
        fileName: image.fileName,
      };
    });

    data.image = [...existingImage, ...newimage];

    // const existingGallery = portfolio?.contents
    //   ? await Promise.all(
    //       portfolio?.contents
    //         ?.filter((image) => image?.contentType === "IMAGES")
    //         .map(async (image) => {
    //           return {
    //             contentType: "IMAGES",
    //             contentPosition: image?.contentPosition,
    //             content: image?.content,
    //           };
    //         })
    //     )
    //   : [];

    const newGallery = Object.keys(gallery)
      .map((key) => {
        if (
          document.querySelector(
            `[name="imagesFile_${gallery[key].content[0].parentPosition}"]`
          )
        ) {
          return {
            contentType: "IMAGES",
            contentPosition: gallery[key].content[0].parentPosition,
            content: gallery[key].content.map((content) => {
              return {
                contentType: "IMAGE",
                contentPosition: Number(content.contentPosition),
                fileData: content.fileData,
                fileName: content.fileName,
              };
            }),
          };
        }
      })
      .filter((v) => v !== undefined);

    if (newGallery?.length > 0) {
      data.images = newGallery;
    }

    data.video = Object.keys(data)
      .map((video) => {
        const index = video.split("_")[1];

        if (
          video.startsWith("videoContent") &&
          document.querySelector(`[name="videoContent_${index}"]`)
        ) {
          return {
            contentType: "VIDEO",
            contentPosition: Number(index),
            content: data[`videoContent_${index}`],
            externalUrl: data[`videoExternalUrl_${index}`],
          };
        }
      })
      .filter((v) => v !== undefined);

    data.text = Object.keys(data)
      .map((text) => {
        const index = text.split("_")[1];

        if (
          text.startsWith("textContent") &&
          document.querySelector(`[name="textContent_${index}"]`)
        ) {
          return {
            contentType: "TEXT",
            contentPosition: Number(index),
            content: data[`textContent_${index}`],
            externalUrl: "",
          };
        }
      })
      .filter((v) => v !== undefined);

    data.contents = [
      ...(data?.video || []),
      ...(data?.image || []),
      ...(data?.images || []),
      ...(data?.text || []),
    ];

    delete data.video;
    delete data.image;
    delete data.images;
    delete data.text;

    if (!data.fileData) delete data.fileData;

    data.contents = data.contents.filter((content, i) => {
      const elementExist = document.getElementById(`field-${i}`);

      if (elementExist) return content;
    });

    data.id = params.project_id;

    const addPortfolio = await post("creatives/portfolio/edit", data);
    if (addPortfolio?.status === "ok") {
      successAlert("Portfolio Edited");

      await userActions.getCurrentUser();
      setIsUpdating(false);
      navigate("/profile", { replace: true });
    } else {
      setIsUpdating(false);
      errorAlert(addPortfolio?.errorMessage || "Failed to add portfolio");
    }
  };

  const handleButtonClick = (buttonName) => {
    if (activeMediaButton === buttonName) {
      setActiveMediaButton(null);
    } else {
      setActiveMediaButton(buttonName);
    }
  };

  const handleGetPortFolio = async () => {
    if (!params?.project_id || portfolio?.title) return;

    const res = await get(`creatives/portfolio/${params?.project_id}`);

    if (res?.creativesPortfolio?.contents) {
      res.creativesPortfolio.contents.sort(
        (a, b) => a.contentPosition - b.contentPosition
      );
    }

    if (res?.status === "ok") {
      setPortfolio(res.creativesPortfolio);

      const existingContent = res?.creativesPortfolio?.contents
        ? res.creativesPortfolio.contents
        : [];

      setContents([...contents, ...existingContent]);
    }
  };

  const handleAddField = () => {
    const newField = { contentType: activeMediaButton, value: "" };

    setContents((prevFields) => [...prevFields, newField]);
  };

  const handleGetCoverImage = async (e) => {
    const portfolioCoverData = await imgToBase64(e);

    setCoverImage(portfolioCoverData);
  };

  const handleGetContentImage = async (e, index) => {
    const portfolioCoverData = await imgToBase64(e);
    portfolioCoverData.index = index;

    const coverImageList =
      contentImage?.length > 0
        ? [
            ...contentImage.filter((ci) => ci.index !== index),
            portfolioCoverData,
          ]
        : [portfolioCoverData];

    setContentImage(coverImageList);
  };

  const drawerVariants = {
    open: { x: 0, opacity: [0, 1] },
    closed: { x: "-5%", opacity: 0 },
  };

  const drawerTransition = {
    type: "tween",
    duration: 0.3,
  };

  const renderAddedFields = (portfolio) => {
    if (portfolio) return <></>;

    return contents.map((field, index) => {
      const handleRemoveField = (fieldIndex) => {
        const fieldElement = document.getElementById(`field-${fieldIndex}`);
        const index = fieldElement.getAttribute("id").split("-")[1];

        if (fieldElement) {
          fieldElement.remove();
        }
      };

      if (field.contentType === "VIDEO") {
        return (
          <div key={index} id={`field-${index}`}>
            <hr />
            <Controller
              className="mb-4"
              control={control}
              name={`videoContent_${index}`}
              rules={{
                validate: (url) => verifyEmbedVideoUrl(url),
              }}
              defaultValue={field?.content}
              render={({ field }) => (
                <>
                  <label
                    htmlFor="form-input"
                    className="form-label w-100 font--inter-semiBold text-black font-16 mb-2 d-flex justify-content-between align-items-center my-4"
                  >
                    <div className="--position-relative">
                      Video URL
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip">
                            Supported Platforms:
                            <div className="p-0 m-0">
                              <div className="d-flex flex-column align-items-start px-2">
                                <span>Facebook</span>
                                <span>Instagram</span>
                                <span>LinkedIn</span>
                                <span>Pinterest</span>
                                <span>TikTok</span>
                                <span>Twitter</span>
                                <span>Youtube</span>
                              </div>
                            </div>
                          </Tooltip>
                        }
                      >
                        <Button className="btn-tooltip bg-transparent border-0 text-black">
                          <FontAwesomeIcon icon={faExclamationCircle} />
                        </Button>
                      </OverlayTrigger>
                    </div>
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="remove-field text-danger --cursor-pointer"
                      onClick={() => handleRemoveField(index)}
                    />
                  </label>
                  <Col xs={12}>
                    <input
                      type="text"
                      id={`videoContent_${index}`}
                      placeholder="Paste ur video url here"
                      {...field}
                      className="form-control w-100"
                    />
                    {errors[`videoContent_${index}`] && (
                      <div className="text-danger mt-2">
                        {errors[`videoContent_${index}`].message}
                      </div>
                    )}
                  </Col>
                </>
              )}
            />

            <Controller
              className="mb-4"
              control={control}
              name={`videoExternalUrl_${index}`}
              defaultValue={field?.externalUrl}
              render={({ field }) => (
                <>
                  <label
                    htmlFor="form-input"
                    className="form-label w-100 font--inter-semiBold text-black font-16 mb-2 d-flex justify-content-between align-items-center my-4"
                  >
                    Caption
                  </label>
                  <Col xs={12}>
                    <input
                      type="text"
                      id={`videoExternalUrl_${index}`}
                      placeholder="Write your own caption for this video"
                      {...field}
                      className="form-control w-100"
                    />
                    {errors[`videoExternalUrl_${index}`] && (
                      <div className="text-danger mb-4">
                        {errors[`videoExternalUrl_${index}`].message}
                      </div>
                    )}
                  </Col>
                </>
              )}
            />
          </div>
        );
      } else if (field.contentType === "TEXT") {
        return (
          <div key={index} id={`field-${index}`}>
            <hr />
            <Controller
              className="mb-4"
              control={control}
              name={`textContent_${index}`}
              defaultValue={field?.content}
              rules={{ required: "Text content is required" }}
              render={({ field }) => (
                <>
                  <label
                    htmlFor="form-input"
                    className="form-label w-100 font--inter-semiBold text-black font-16 mb-2 d-flex justify-content-between align-items-center"
                  >
                    Text Content
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="remove-field text-danger --cursor-pointer"
                      onClick={() => handleRemoveField(index)}
                    />
                  </label>
                  <Col xs={12}>
                    <textarea
                      id={`textFile_${index}`}
                      name={`textFile_${index}`}
                      rows={5}
                      {...field}
                      className="w-100 border-0 py-2 px-3"
                      placeholder="Text content"
                    />
                    {errors[`textContent_${index}`] && (
                      <div className="text-danger mt-2">
                        {errors[`textContent_${index}`].message}
                      </div>
                    )}
                  </Col>
                </>
              )}
            />
          </div>
        );
      } else if (field.contentType === "IMAGE") {
        return (
          <div key={index} id={`field-${index}`}>
            <hr />
            <>
              <label
                htmlFor="form-input"
                className="form-label w-100 font--inter-semiBold text-black font-16 mb-2 d-flex justify-content-between align-items-center"
              >
                Edit Portfolio Image
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="remove-field text-danger --cursor-pointer"
                  onClick={() => handleRemoveField(index)}
                />
              </label>
              <Col xs={12}>
                <label
                  id="portfolio-image"
                  htmlFor="portfolio-image"
                  className="w-100 my-3 d-flex align-items-center justify-content-center p-5 mb-3 font--inter-medium font-16 blue-text rounded blue-border --border-3px-dashed --cursor-pointer --bg-no-repeat"
                  style={{
                    backgroundImage: `url(${
                      contentImage.find((ci) => ci.index === index)
                        ?.filePreview || field?.imageUrl
                    })`,
                  }}
                >
                  <input
                    type="file"
                    id={`imageFile_${index}`}
                    accept="image/*"
                    onChange={(e) => handleGetContentImage(e, index)}
                    className="form-control w-100"
                    style={{
                      position: "absolute",
                      height: 300,
                      width: "100%",
                      opacity: 0,
                      zIndex: 2,
                    }}
                  />

                  <div className="d-flex flex-column align-items-center">
                    <img
                      src="/assets/icons/camera.png"
                      alt="camera"
                      className="mb-2"
                      id="profile-image-src"
                    />

                    <span className="text-center">Add Image</span>
                  </div>
                </label>
              </Col>
            </>

            <Controller
              className="mb-4"
              control={control}
              name={`imageContent_${index}`}
              defaultValue={field?.content}
              render={({ field }) => (
                <>
                  <label
                    htmlFor="form-input"
                    className="form-label w-100 font--inter-semiBold text-black font-16 mb-2 d-flex justify-content-between align-items-center"
                  >
                    Caption
                  </label>
                  <Col xs={12}>
                    <textarea
                      id={`textFile_${index}`}
                      name={`textFile_${index}`}
                      rows={5}
                      {...field}
                      className="w-100 border-0 py-2 px-3"
                      placeholder="Write your own caption for this image"
                    />
                    {errors[`imageContent_${index}`] && (
                      <div className="text-danger mt-2">
                        {errors[`imageContent_${index}`].message}
                      </div>
                    )}
                  </Col>
                </>
              )}
            />
          </div>
        );
      } else if (field.contentType === "GALLERY") {
        return (
          <div key={index} id={`field-${index}`}>
            <hr />
            <>
              <label
                htmlFor="form-input"
                className="form-label w-100 font--inter-semiBold text-black font-16 mb-2 d-flex justify-content-between align-items-center"
              >
                Add Image Gallery To This Portfolio
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="remove-field text-danger --cursor-pointer"
                  onClick={() => handleRemoveField(index)}
                />
              </label>
              <Col xs={12}>
                <div className="--position-relative">
                  {gallery[`field${index}`]?.content?.length ||
                  field?.contents?.length > 0 ? (
                    <Carousel
                      className={"cc-carousel portfolio-gallery-slider mb-5"}
                    >
                      {(
                        gallery[`field${index}`]?.content || field?.contents
                      ).map((slide, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <img
                              className="d-block w-100 portfolio-carousel-item"
                              src={slide?.filePreview || slide?.imageUrl}
                              alt=""
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  ) : (
                    <label
                      id="portfolio-image"
                      htmlFor="portfolio-image"
                      className="w-100 my-3 d-flex align-items-center justify-content-center p-5 mb-3 font--inter-medium font-16 blue-text rounded blue-border --border-3px-dashed --cursor-pointer --bg-no-repeat"
                      style={{
                        backgroundImage: `url(${
                          contentImage.find((ci) => ci.index === index)
                            ?.filePreview
                        })`,
                      }}
                    >
                      <input
                        type="file"
                        id={`imageFile_${index}`}
                        accept="image/*"
                        onChange={(e) => handleGetContentImage(e, index)}
                        className="form-control w-100"
                        style={{
                          position: "absolute",
                          height: 300,
                          width: "100%",
                          opacity: 0,
                          zIndex: 2,
                        }}
                      />

                      <div className="d-flex flex-column align-items-center">
                        <img
                          src="/assets/icons/camera.png"
                          alt="camera"
                          className="mb-2"
                          id="profile-image-src"
                        />

                        <span className="text-center">Add Image</span>
                      </div>
                    </label>
                  )}

                  <input
                    type="file"
                    id={`imagesFile_${index}`}
                    name={`imagesFile_${index}`}
                    accept="image/*"
                    multiple
                    onChange={(e) => onAddGalleryImages(e, index)}
                    className="form-control w-100"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: 300,
                      width: "100%",
                      opacity: 0,
                      zIndex: 2,
                    }}
                  />
                </div>
              </Col>
            </>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (!portfolio?.title && params?.project_id) {
      handleGetPortFolio();
    }

    if (portfolio?.title) {
      setValue("title", portfolio.title);
      setValue("description", portfolio.description);

      renderAddedFields(portfolio);
    }
  }, [portfolio, params]);

  useEffect(() => {}, [contents]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="d-flex justify-content-between">
        <Col xs={12} className="mb-4">
          <Controller
            className="my-4"
            control={control}
            name="title"
            defaultValue=""
            rules={{ required: "Title is required" }}
            render={({ field }) => (
              <>
                <label
                  htmlFor="form-input"
                  className="font--inter-semiBold text-black font-16 mb-2"
                >
                  Portfolio Headline
                </label>
                <input
                  type="text"
                  id="title"
                  placeholder="Portfolio headline"
                  {...field}
                  className="form-control w-100"
                />
                {errors.title && (
                  <div className="text-danger mt-2">{errors.title.message}</div>
                )}
              </>
            )}
          />
        </Col>

        <Col xs={12} className="mb-4">
          <Controller
            className="mb-4"
            control={control}
            name="description"
            defaultValue=""
            rules={{ required: "Description is required" }}
            render={({ field }) => (
              <>
                <label
                  htmlFor="form-input"
                  className="form-label w-100 font--inter-semiBold text-black font-16 mb-2"
                >
                  Description
                </label>
                <Col xs={12}>
                  <textarea
                    rows={5}
                    id="description"
                    {...field}
                    className="w-100 border-0 py-2 px-3"
                    placeholder="Portfolio description"
                  />
                  {errors.description && (
                    <div className="text-danger mt-2">
                      {errors.description.message}
                    </div>
                  )}
                </Col>
              </>
            )}
          />
        </Col>

        <Col xs={12} className="mb-4">
          <>
            <label
              htmlFor="form-input"
              className="form-label w-100 font--inter-semiBold text-black font-16 mb-2 d-flex justify-content-between align-items-center"
            >
              Headline Cover Photo
            </label>
            <Col xs={12}>
              <label
                id="profile-banner-label"
                htmlFor="profile-image"
                className="w-100 my-3 d-flex align-items-center justify-content-center p-5 mb-3 font--inter-medium font-16 blue-text rounded blue-border --border-3px-dashed --cursor-pointer --bg-no-repeat"
                style={{
                  backgroundImage: `url(${
                    coverImage?.filePreview || portfolio?.imageUrl
                  })`,
                }}
              >
                <input
                  type="file"
                  id="cover-image"
                  accept="image/*"
                  onChange={(e) => handleGetCoverImage(e)}
                  className="form-control w-100"
                  style={{
                    position: "absolute",
                    height: 300,
                    width: "100%",
                    opacity: 0,
                    zIndex: 2,
                  }}
                />

                {coverImage.length < 1 && (
                  <div className="d-flex flex-column align-items-center">
                    <img
                      src="/assets/icons/camera.png"
                      alt="camera"
                      className="mb-2"
                      id="profile-image-src"
                    />

                    <span className="text-center">Upload Image</span>
                  </div>
                )}
              </label>
            </Col>
          </>
        </Col>

        <div className="my-4 d-flex justify-content-start align-items-center">
          <Button
            variant="transparent"
            onClick={() => setShowDrawer(!showDrawer)}
          >
            <FontAwesomeIcon icon={faPlus} /> Add Media
          </Button>

          {showDrawer && (
            <motion.div
              initial="closed"
              animate={showDrawer ? "open" : "closed"}
              variants={drawerVariants}
              transition={drawerTransition}
              className={`custom-drawer ${
                showDrawer ? "open" : ""
              } d-flex justify-content-between align-items-center w-75`}
            >
              <div>
                <Button
                  variant={activeMediaButton === "TEXT" ? "dark" : "light"}
                  className="--border-radius-circle mx-1 btn-add-media"
                  onClick={() => handleButtonClick("TEXT")}
                >
                  <FontAwesomeIcon icon={faFileText} />
                </Button>
                <Button
                  variant={activeMediaButton === "IMAGE" ? "dark" : "light"}
                  className="--border-radius-circle mx-1 btn-add-media"
                  onClick={() => handleButtonClick("IMAGE")}
                >
                  <FontAwesomeIcon icon={faImage} />
                </Button>
                <Button
                  variant={activeMediaButton === "GALLERY" ? "dark" : "light"}
                  className="--border-radius-circle mx-1 btn-add-media"
                  onClick={() => handleButtonClick("GALLERY")}
                >
                  <FontAwesomeIcon icon={faImages} />
                </Button>
                <Button
                  variant={activeMediaButton === "VIDEO" ? "dark" : "light"}
                  className="--border-radius-circle mx-1 btn-add-media"
                  onClick={() => handleButtonClick("VIDEO")}
                >
                  <FontAwesomeIcon icon={faPlayCircle} />
                </Button>
              </div>

              {activeMediaButton && (
                <Button
                  className="bg-blue border-0 mx-0 px-1"
                  onClick={() => handleAddField()}
                >
                  <FontAwesomeIcon icon={faPlus} className="px-2" />
                  <span className="px-2">{activeMediaButton}</span>
                </Button>
              )}
            </motion.div>
          )}
        </div>

        {renderAddedFields()}

        <button
          type="submit"
          className="mt-5 btn bg-blue border-1 border-radius-0 p-1 ps-3 pe-3 text-white border-dark fw-700 mb-2 w-100"
        >
          {isUpdating ? (
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
          ) : (
            "Submit"
          )}
        </button>
      </Row>
    </form>
  );
};

const mapStateToProps = (props) => ({});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProject);
