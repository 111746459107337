import { Outlet } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const EmployerProfileLayout = ({ children, coverImage, isBannerHidden }) => {
  return (
    <>
      {!isBannerHidden && (
        <Container fluid className={"p-0"}>
          <Row className="m-0">
            <Col className={"p-0"}>
              <img
                className="employer-banner"
                alt="Profile Banner"
                src={coverImage || "/assets/images/placeholders/11.jpg"}
              />
            </Col>
          </Row>
        </Container>
      )}
      <div>
        <Outlet />
      </div>
      {children}
    </>
  );
};

export default EmployerProfileLayout;
