import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <Container className="footer-container p-3 mt-3">
      <Row className="align-items-center">
        <Col sm={8}>
          <div className="ftr-links font-16 fw-700 text-black ftr-left-menu d-flex flex-wrap justify-content-start">
            <a href="http://www.cultcreativeasia.com">Cult Creative Asia</a>
            {/* <a href="https://www.cultcreative.asia/contact-us">Contact</a>
            <a href="https://www.cultcreative.asia/about">Who's in the cult?</a>
            <a href="https://www.cultcreative.asia/faq">FAQ</a>
            <a href="https://www.cultcreative.asia/community-guidelines">
              App Community Guidelines
            </a> */}
            <a href="https://www.cultcreativeasia.com/privacy-policy">
              Privacy Policy
            </a>
          </div>
        </Col>
        <Col sm={4}>
          <div className="ftr-social-links ftr-right-menu d-flex flex-row justify-content-end">
            {/* <Link
              to="https://www.facebook.com/cultcreativeapp/"
              target="_blank"
            >
              <img src="/assets/icons/footer-icons/fb.svg" alt="Facebook" />
            </Link> */}
            <Link
              to="https://www.instagram.com/cultcreativeasia"
              target="_blank"
            >
              <img src="/assets/icons/footer-icons/ig.svg" alt="Instagram" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/cultcreativeapp/"
              target="_blank"
            >
              <img src="/assets/icons/footer-icons/li.svg" alt="Linkedin" />
            </Link>
            <Link
              to="https://www.youtube.com/channel/UCY_ZBFtA0Z9vF_V_O0P4XfQ"
              target="_blank"
            >
              <img src="/assets/icons/footer-icons/yt.svg" alt="Youtube" />
            </Link>
            {/* <Link to="https://discord.com/invite/bCkb9fuCQx" target="_blank">
              <img src="/assets/icons/footer-icons/ds.svg" alt="Discord" />
            </Link> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
