import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import LoginHeader from "../Headers/LoginHeader";

export default function LoginLayout(props) {
  return (
    <>
      <Container fluid className={"login-layout"}>
        <LoginHeader></LoginHeader>
        <Row className={"vh-100 overflow-hidden login-layout-row"}>
          <div className={"d-none d-md-block col col-sm-8 login-layout-bg p-5"}>
            <div
              className={
                "login-layout-banner d-flex flex-column h-100 justify-content-top"
              }
            >
              <Link to="/">
                <img
                  className="me-3 w-200px"
                  src="/assets/images/logos/logo-white.png"
                  alt="logo"
                />
              </Link>
              <div className="mt-40">
                <p className={"text-white font-20 font-family-futuram"}>
                  Career discovery and networking for Southeast Asia’s creative
                  workforce.
                </p>
                <p className={"text-white font-family-futuram"}>
                  {/* <FontAwesomeIcon className={"me-1"} icon={faCheck} /> */}
                  Create a profile + portfolio to apply to unique creative jobs
                  in graphic design, social media, content creation, videography and more.
                  {/* <br />
                  <FontAwesomeIcon className={"me-1"} icon={faCheck} />
                  Unique creative jobs in graphic design, social media, content
                  creation, videography and more <br />
                  <FontAwesomeIcon className={"me-1"} icon={faCheck} />
                  Get our app to find a community of 10,000 creatives just like
                  you
                  <br />
                  <FontAwesomeIcon className={"me-1"} icon={faCheck} />
                  Work where you are to achieve flexibility and creative freedom
                  <br /> */}
                </p>
              </div>
            </div>
          </div>
          <Col sm={12} md={4} className={"p-md-3 p-lg-5"}>
            <div className={"d-flex flex-wrap align-items-center h-100 w-100"}>
              {props.children}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
