export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_FAILURE = "AUTHENTICATE_USER_FAILURE";
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const BOOKMARK_SUCCESS = "BOOKMARK_SUCCESS";
export const BOOKMARK_FAILURE = "BOOKMARK_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const EMPTY_STATUS = "EMPTY_STATUS";
export const IS_LOADING = "IS_LOADING";
export const SELECTED_JOB_SUCCESS = "SELECTED_JOB_SUCCESS";
export const SELECTED_JOB_FAILURE = "SELECTED_JOB_FAILURE";
export const APPLY_JOB_SUCCESS = "APPLY_JOB_SUCCESS";
export const APPLY_JOB_FAILURE = "APPLY_JOB_FAILURE";
export const GET_ALL_RESUMES = "GET_ALL_RESUMES";
export const UPLOAD_RESUME_SUCCESS = "UPLOAD_RESUME_SUCCESS";
export const UPLOAD_RESUME_FAILURE = "UPLOAD_RESUME_FAILURE";
export const FILTER_DATA = "FILTER_DATA";
export const FILTER_BY_JOB = "FILTER_BY_JOB";
export const SEARCH_QUERY = "SEARCH_QUERY";
export const SET_ALL_JOBS = "SET_ALL_JOBS";
export const SET_PAGE_INDEX = "SET_PAGE_INFO";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";
export const GET_ALL_PHONE_PREFIXES = "GET_ALL_PHONE_PREFIXES";
export const FILTER_DROPDOWN_DATA = "FILTER_DROPDOWN_DATA";
