import React, { useState } from "react";
import * as Actions from "../../redux/actions/jobAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark as faBookmarkUnchecked } from "@fortawesome/free-regular-svg-icons";
import { faBookmark as faBookmarkChecked } from "@fortawesome/free-solid-svg-icons";

const Bookmark = ({
  actions,
  jobId,
  isLoggedIn,
  userData,
  isBookmarked,
  bookmarkPlacement,
}) => {
  const navigate = useNavigate();

  const [isBd, setIsBD] = useState(false);

  const addToBookmark = (event, jobId) => {
    event.stopPropagation();
    if (isLoggedIn && userData) {
      actions.addToBookmark(jobId);
      setIsBD(!isBd);
    } else {
      navigate(`/login?jobId=${jobId}`, { replace: true });
    }
  };

  return (
    <>
      {bookmarkPlacement && bookmarkPlacement === "details" ? (
        <button
          onClick={(event) => addToBookmark(event, jobId)}
          className="btn font-32 text-white border-0 p-0 bookmark-top-right"
        >
          <FontAwesomeIcon
            icon={isBd ? faBookmarkChecked : faBookmarkUnchecked}
          />
        </button>
      ) : (
        <button
          onClick={(event) => addToBookmark(event, jobId)}
          className="btn border-0 p-0"
        >
          <FontAwesomeIcon
            icon={isBd ? faBookmarkChecked : faBookmarkUnchecked}
          />
        </button>
      )}
    </>
  );
};
const mapStateToProps = (props, parentProps) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  userData: props.userReducer.user,
  isBookmarked: parentProps.isBookmarked,
  bookmarkPlacement: parentProps.placement,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Bookmark);
