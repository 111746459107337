import HeaderDesktop from "../Headers/HeaderDesktop";
import HeaderMobile from "../Headers/HeaderMobile";

export default function Header(props) {
    return (
        <>
        <HeaderDesktop />
        <HeaderMobile />
        </>
    );
}