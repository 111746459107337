import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as JobActions from "../../redux/actions/jobAction";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation } from "react-router-dom";

const SearchBar = ({ jobActions, filterData, isLogged, searchStyle }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { jobQuery, pageIndex, pageSize } = useSelector(
    ({ jobReducer }) => jobReducer
  );

  const [show, setShow] = useState(false);
  const [inputSearch, setInputSearch] = useState("");

  const handleFilters = (event) => {
    event.preventDefault();
    setShow(!show);
  };

  const [filterType] = useState([
    { value: "employerIndustries", heading: "Industry" },
    { value: "experienceLevels", heading: "Experience Level" },
    { value: "locationsData", heading: "Location" },
    { value: "workingDays", heading: "Working Days" },
    { value: "educationLevels", heading: "Education Level" },
    { value: "employerCompanySizes", heading: "Company Size" },
    { value: "employmentTypes", heading: "Job Type" },
    { value: "salaryRanges", heading: "Salary Range" },
    { value: "workModes", heading: "Work Modes" },
  ]);

  const [filters, setFilter] = useState();
  const [selectedFilter, setSelectedFilter] = useState({
    employerIndustries: [],
    experienceLevels: [],
    locationsData: [],
    workingDays: [],
    educationLevels: [],
    employerCompanySizes: [],
    employmentTypes: [],
    salaryRanges: [],
    workModes: [],
  });

  const handleChange = (e, type) => {
    const { value, checked } = e.target;
    const data = selectedFilter[type];

    if (checked) {
      data.push(value);
      setSelectedFilter({
        ...selectedFilter,
        [type]: data,
      });
    } else {
      const unCheckData = data.filter((e) => e !== value);
      setSelectedFilter({
        ...selectedFilter,
        [type]: unCheckData,
      });
    }
  };

  const handleInputChange = ({ target: { value } }) => {
    setInputSearch(value);
    dispatch(JobActions.jobSearchQuery(value));
  };

  const getFilterValue = (value) => {
    return value ? value : null;
  };

  const search = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    !jobQuery && dispatch(JobActions.setPageIndex(0));

    const searchObj = {
      pageIndex: !jobQuery ? 0 : pageIndex,
      pageSize,
      educationLevelId: getFilterValue(
        selectedFilter.educationLevels.join(",")
      ),
      employerCompanySizeId: getFilterValue(
        selectedFilter.employerCompanySizes.join(",")
      ),
      employerIndustryId: getFilterValue(
        selectedFilter.employerIndustries.join(",")
      ),
      employmentTypeId: getFilterValue(
        selectedFilter.employmentTypes.join(",")
      ),
      experienceLevelId: getFilterValue(
        selectedFilter.experienceLevels.join(",")
      ),
      salaryRangeId: getFilterValue(selectedFilter.salaryRanges.join(",")),
      workLocationId: getFilterValue(selectedFilter.locationsData.join(",")),
      workModeId: getFilterValue(selectedFilter.workModes.join(",")),
      workingDayId: getFilterValue(selectedFilter.workingDays.join(",")),
      keyword: jobQuery,
    };

    jobActions.getAllJobs("home", searchObj, "filter");
  };
  useEffect(() => {
    jobActions.getFilters();
  }, []);
  useEffect(() => {
    setFilter(filterData);
  }, [filterData]);

  const filterValue = (type, data) => {
    switch (type) {
      // case "salaryRanges":
      //   return `${data.rangeStartValue} - ${
      //     data.rangeEndValue ? data.rangeEndValue : ""
      //   }`;
      // case "employerCompanySizes":
      //   return `${data.sizeStartValue} - ${
      //     data.sizeEndValue ? data.sizeEndValue : ""
      //   }`;
      // case "experienceLevels":
      //   return `${data.yearsStartValue} - ${
      //     data.yearsEndValue ? data.yearsEndValue : ""
      //   }`;
      default:
        return data.value;
    }
  };

  if (location?.pathname !== "/" && location?.pathname !== "/jobs")
    return <></>;

  return (
    <>
      <form
        className="hdr-search-form col-sm-10 position-relative"
        onSubmit={(event) => search(event)}
        style={{ maxWidth: 450 }}
      >
        <div className="form-group input-group has-search position-relative">
          <input
            onChange={handleInputChange}
            value={inputSearch}
            style={searchStyle}
            className="hdr-search-bar form-control rounded-pill border border-dark text-dark border-3"
            type="text"
            placeholder="Search Job Type, Employer, Specialization or others..."
          />

          <div className="d-flex flex-row align-items-center position-absolute h-100 me-3 end-0">
            <Dropdown className="" autoClose="outside">
              <Dropdown.Toggle
                id="dropdown-autoclose-outside"
                className="search-filter-btn border-0 p-0 me-2"
              >
                <img src="/assets/icons/search.png" alt="search.png" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="filters-main p-3 border-radius-0">
                {filterData && filterData.employmentTypes ? (
                  <Accordion>
                    {filterType.map((resp, index) => {
                      return (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header>{resp.heading}</Accordion.Header>
                          <Accordion.Body>
                            {filterData[resp.value].map((data, index) => {
                              return (
                                <div key={index}>
                                  <label>
                                    <input
                                      onChange={(event) =>
                                        handleChange(event, resp.value)
                                      }
                                      type={"checkbox"}
                                      name={"job_type[]"}
                                      value={data.id}
                                    />
                                    {filterValue(resp.value, data)}
                                  </label>
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
            <button
              onClick={(event) => search(event)}
              type="button"
              id="search-btn"
              className="p-0"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
const mapStateToProps = (props, parentProps) => ({
  isLogged: props.userReducer.isLoggedIn,
  filterData: props.jobReducer.filters,
  searchStyle: parentProps.style,
});

const mapDispatchToProps = (dispatch) => ({
  jobActions: bindActionCreators(JobActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
