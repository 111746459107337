import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Logo from "../Commons/Logo"
import Menu from "../Menu/MenuMobile"

export default function LoginHeader(props) {
    return (
        <>
            <div className="d-block d-md-none">
                <Container fluid className="header p-3 ps-0 pe-0">
                    <Row className="align-items-center">
                        <Col className="text-start">
                            <Logo img="mobile-logo" style={{ height: "50px" }} />
                        </Col>
                        <Col className="text-end d-flex align-items-center justify-content-end">
                            <Menu />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}