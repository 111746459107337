import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Controller, useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../../../../redux/actions/userAction";
import * as jobActions from "../../../../redux/actions/jobAction";
import * as utilsActions from "../../../../redux/actions/utilsAction";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import DatePicker from "../../../../components/DatePicker";
import useProfile from "../../hooks/useProfile";
import { Button, Image, Modal } from "react-bootstrap";
import { useAxios } from "../../../../api/useAxios";
import { errorAlert, successAlert } from "../../../../constants/helper";
import Select from "react-select";
import { useNavigate } from "react-router";
import Loader from "../../../../components/Commons/Loader";
import { ResetPasswordForm } from "../../../../routes/reset_password";
import AccountInfoFields from "./AccountInfo/AccountInfoFields";

const EditProfileDetails = ({
  userActions,
  jobActions,
  user,
  isLogged,
  phonePrefixes,
  dropdownFilters,
  orangeTick,
  setOrangeTick,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { get, post } = useAxios();

  useEffect(() => {
    !isLogged && navigate("/jobs", { replace: true });
  }, [isLogged]);

  const {
    profile,
    profileImage,
    setProfileThumbnail,
    onProfileImageChange,
    headerImage,
    setHeaderThumbnail,
    onHeaderImageChange,
  } = useProfile(user);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedPhoneNumberPrefix, setSelectedPhoneNumberPrefix] = useState(1);
  const [selectedEducationLevel, setSelectedEducationLevel] = useState();
  const [selectedEmploymentType, setSelectedEmploymentType] = useState();
  const [selectedSalaryRange, setSelectedSalaryRange] = useState();
  const [selectedCurrentEmploymentType, setSelectedCurrentEmploymentType] =
    useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [stateOptions, setStateOptions] = useState();
  const [selectedNationality, setSelectedNationality] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [links, setLinks] = useState([]);
  const [existingLinks, setExistingLinks] = useState();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const existingUserLinksRef = useRef();
  const userLinksRef = useRef();

  const handleAddLink = () => {
    setLinks([...links, ""]);
  };

  const handleExistingLinkChange = (e, index) => {
    const newLinks = [...existingLinks];

    newLinks[index] = {
      ...newLinks[index],
      value: e.target.value,
      userLinkType: document.getElementById(`existingUserLinkType${index}`)
        .value,
    };

    setExistingLinks(newLinks);
  };

  const handleLinkChange = (e, index) => {
    const newLinks = [...links];

    newLinks[index] = {
      value: e.target.value,
      userLinkType: document.getElementById(`userLinkType${index}`).value,
    };

    setLinks(newLinks);
  };

  const {
    register,
    control,
    reset,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const watchFields = watch();

  const educationLevelOptions = [];
  dropdownFilters?.educationLevels?.length > 0 &&
    dropdownFilters.educationLevels.map((resp) => {
      let k = {};
      k.value = +resp.id;
      k.label = resp.value;
      educationLevelOptions.push(k);
    });

  const employmentTypeOptions = [];
  dropdownFilters?.employmentTypes?.length > 0 &&
    dropdownFilters.employmentTypes.map((resp) => {
      let k = {};
      k.value = +resp.id;
      k.label = resp.value;
      employmentTypeOptions.push(k);
    });

  const creativesSalaryRangesOptions = [];
  dropdownFilters?.salaryRanges?.length > 0 &&
    dropdownFilters.salaryRanges.map((resp) => {
      let k = {};
      k.value = +resp.id;
      k.label = resp.value;
      creativesSalaryRangesOptions.push(k);
    });

  const countriesOptions =
    dropdownFilters?.countries?.map(({ value, id }) => ({
      label: value,
      value: id,
    })) ?? [];

  const nationalitiesOptions =
    dropdownFilters?.nationalities?.map(({ value, id }) => ({
      label: value,
      value: id,
    })) ?? [];

  const handleEditProfile = async (data) => {
    data.phoneNumberDataId = parseInt(selectedPhoneNumberPrefix);

    if (!profileImage && !user?.profileImageUrl) {
      setError("profileImage", {
        type: "manual",
        message: "Profile Picture is required",
      });

      return;
    } else {
      data.profileImage = profileImage;
    }

    if (!headerImage && !user?.profileBannerUrl) {
      setError("headerImage", {
        type: "manual",
        message: "Cover Picture is required",
      });

      return;
    } else {
      data.headerImage = headerImage;
    }

    data.dateOfBirth = new Date(dateOfBirth)
      .toLocaleDateString()
      .split("/")
      .reverse()
      .join("-")
      .toString();

    data.educationLevelId =
      data.educationLevelId[0]?.value || data.educationLevelId.value;

    data.creativesEmploymentTypes = [
      data?.creativesEmploymentTypes[0]?.value ||
        data.creativesEmploymentTypes.value,
    ];

    data.creativesEmploymentPosition = {
      employmentTypeId:
        data?.currentEmploymentTypeId[0]?.value ||
        data.currentEmploymentTypeId.value,
      positionTitle: data.currentPositionTitle,
      positionCompany: data.currentPositionCompany,
      details: data.currentPositionDetails,
    };

    data.creativesSalaryRanges = [
      data?.creativesSalaryRanges[0]?.value || data.creativesSalaryRanges.value,
    ];

    if (data?.countryId)
      data.countryId = data?.countryId[0]?.value || data.countryId.value;

    data.stateId = selectedState.value;

    data.userLinks = existingLinks
      ? [...new Set([...existingLinks, ...links])].filter((f) => f.value !== "")
      : links;

    data.nationalityId = data.nationalityId.value;

    const requestError = [];

    try {
      const updatePersonalInfo = await userActions.editProfile(data, true);
      const updateOrangeTick = await post("user/orange-tick", data);
      const uploadProfileImage = await post(
        "uploads/upload/image/profile",
        profileImage
      );
      const uploadHeaderImage = await post(
        "uploads/upload/image/cover",
        headerImage
      );

      [
        (updatePersonalInfo,
        updateOrangeTick,
        uploadProfileImage,
        uploadHeaderImage),
      ].map(
        ({ errorMessage }) => errorMessage && requestError.push(errorMessage)
      );

      successAlert("Profile Updated Successfully");

      await userActions.getCurrentUser();

      setTimeout(() => {
        navigate("/profile", { replace: true });
      }, 500);
    } catch (error) {
      errorAlert("Error on updating profile");
      console.error(error, requestError);
    }
  };

  const previousValueCountryRef = useRef();

  const handleGetState = async () => {
    if (selectedCountry?.value === previousValueCountryRef?.current) return;
    if (!selectedCountry || !selectedCountry.value) return;

    const res = await get(
      `utility/data/locations/cities/${selectedCountry.value}`
    );

    if (res && res.locationsData) {
      const states = res.locationsData.map(({ value, id }) => ({
        label: value,
        value: id,
      }));

      setStateOptions(states);

      if (user?.stateId) {
        setSelectedState(states.find((state) => state.value === user.stateId));
        previousValueCountryRef.current = selectedCountry.value;
      }
    }
  };

  useEffect(() => {
    if (user?.name && dropdownFilters) {
      setSelectedEducationLevel(
        user?.educationLevel
          ? [{ label: user?.educationLevel, value: user?.educationLevelId }]
          : ""
      );

      user.currentPositionEmploymentTypeId &&
        setSelectedCurrentEmploymentType([
          {
            label: dropdownFilters?.employmentTypes?.find(
              (et) => et.id == user.currentPositionEmploymentTypeId
            ).value,
            value: user?.currentPositionEmploymentTypeId,
          },
        ]);

      user.preferredEmploymentTypeIds &&
        setSelectedEmploymentType([
          {
            label: dropdownFilters?.employmentTypes?.find(
              (et) => et.id == user?.preferredEmploymentTypeIds[0]
            ).value,
            value: user?.preferredEmploymentTypeIds[0],
          },
        ]);

      setDateOfBirth(
        user?.dateOfBirth ? new Date(user.dateOfBirth) : new Date()
      );

      setSelectedCountry(
        user?.countryId
          ? {
              label: dropdownFilters?.countries?.find(
                (country) => country.id === user?.countryId
              )?.value,
              value: user?.countryId,
            }
          : { label: "Malaysia", value: 1 }
      );

      setSelectedSalaryRange(
        user?.preferredSalaryRangeIds
          ? [
              {
                label: dropdownFilters?.salaryRanges?.find(
                  (sr) => sr.id == user?.preferredSalaryRangeIds[0]
                )?.value,
                value: user?.preferredSalaryRangeIds[0],
              },
            ]
          : []
      );

      !existingLinks && setExistingLinks([...(user?.userLinks || [])]);

      reset({
        name: user?.name,
        phoneNumber: user?.phoneNumber,
        educationLevelId: [
          { label: user?.educationLevel, value: user?.educationLevelId },
        ],
        creativesEmploymentTypes: user?.preferredEmploymentTypeIds
          ? [
              {
                label: dropdownFilters?.employmentTypes?.find(
                  (et) => et.id == user?.preferredEmploymentTypeIds[0]
                )?.value,
                value: user?.preferredEmploymentTypeIds[0],
              },
            ]
          : [],
        bio: user?.bio,
        currentEmploymentTypeId: user?.creativesEmploymentPosition
          ? [
              {
                label: dropdownFilters?.employmentTypes?.find(
                  (et) =>
                    et.id == user?.creativesEmploymentPosition?.employmentTypeId
                )?.value,
                value: user?.creativesEmploymentPosition?.employmentTypeId,
              },
            ]
          : [],
        creativesSalaryRanges: user?.preferredSalaryRangeIds
          ? [
              {
                label: dropdownFilters?.salaryRanges?.find(
                  (sr) => sr.id == user?.preferredSalaryRangeIds[0]
                )?.value,
                value: user?.preferredSalaryRangeIds[0],
              },
            ]
          : [],
        currentPositionCompany:
          user?.creativesEmploymentPosition?.positionCompany,
        currentPositionTitle: user?.creativesEmploymentPosition?.positionTitle,
        currentPositionDetails: user?.creativesEmploymentPosition?.details,
        countryId: user?.countryId
          ? [
              {
                label: dropdownFilters?.countries?.find(
                  (country) => country.id === user.countryId
                ).value,
                value: user.countryId,
              },
            ]
          : [{ label: "Malaysia", value: 1 }],
        stateId: selectedState,
        nationalityId: selectedNationality,
      });
    }
  }, [dropdownFilters]);

  const profileOptions = {
    firstName: { required: "First Name is required" },
    lastName: { required: "Last Name is required" },
    phoneNumber: { required: "Phone Number is required" },
    educationLevelId: { required: "Education Level is required" },
    creativesEmploymentTypes: {
      required: "Preferred Employment Type is required",
    },
    bio: { required: "About me is required" },
    currentEmploymentType: { required: "Your Job Type is required" },
    creativesSalaryRanges: { required: "Expected Salary is required" },
    currentPositionTitle: { required: "Your Job Title/Position is required" },
    currentPositionCompany: { required: "Company is required" },
    currentPositionDetails: { required: "Company detail is required" },
    countryId: { required: "Country is required" },
    stateId: { required: "City is required" },
    nationalityId: { required: "Nationality is required" },
  };

  useEffect(() => {
    phonePrefixes.id &&
      user?.phoneNumber &&
      phonePrefixes?.length > 0 &&
      setSelectedPhoneNumberPrefix(
        () =>
          phonePrefixes.find((prefix) =>
            user.phoneNumber.startsWith(prefix.callingCode)
          ).id
      );
  }, [user, phonePrefixes]);

  useEffect(() => {
    !selectedState &&
      !stateOptions &&
      selectedCountry &&
      handleGetState(selectedCountry.value);
  }, [selectedState, stateOptions]);

  useEffect(() => {
    let isMounted = true;

    handleGetState();

    return () => {
      isMounted = false;
    };
  }, [selectedCountry]);

  useEffect(() => {
    jobActions.getJobFilters();
    dispatch(utilsActions.getPhoneNumberPrefixes());
  }, []);

  useEffect(() => {
    !selectedNationality &&
      user?.nationalityId &&
      setSelectedNationality([
        {
          label: user.nationality,
          value: user.nationalityId,
        },
      ]);
  }, [user, selectedNationality]);

  // if (!dropdownFilters || !selectedCountry || !stateOptions || !selectedState)
  //   return <Loader />;

  useLayoutEffect(() => {
    if (
      orangeTick.tick1 < 25 &&
      (user?.profileImageUrl !== "" ||
        user?.profileImageUrl !== undefined ||
        profileImage !== "" ||
        profileImage !== undefined) &&
      (user?.profileBannerUrl !== "" ||
        user?.profileBannerUrl !== undefined ||
        headerImage !== "" ||
        headerImage !== undefined)
    ) {
      setOrangeTick({ ...orangeTick, tick1: 25 });
    }
  }, [
    profileImage,
    headerImage,
    user?.profileImageUrl,
    user?.profileBannerUrl,
  ]);

  useLayoutEffect(() => {
    if (
      orangeTick.tick2 === 0 &&
      (watchFields?.educationLevelId?.value || user?.educationLevelId) &&
      (watchFields?.creativesEmploymentTypes?.length > 0 ||
        user?.preferredEmploymentTypeIds?.length > 0) &&
      (watchFields?.bio || user?.bio)
    ) {
      setOrangeTick({ ...orangeTick, tick2: 25 });
    }

    if (
      orangeTick?.tick3 === 0 &&
      (watchFields?.currentEmploymentTypeId ||
        user?.currentPositionEmploymentTypeId) &&
      (watchFields?.creativesSalaryRanges?.length ||
        user?.preferredSalaryRangeIds?.length > 0) &&
      (watchFields?.currentPositionCompany ||
        user?.currentPositionCompanyName) &&
      (watchFields?.currentPositionTitle || user?.currentPositionTitle)
    ) {
      setOrangeTick({ ...orangeTick, tick3: 25 });
    }

    if (
      orangeTick.tick4 === 0 &&
      (watchFields?.nationalityId?.value || user?.nationality) &&
      (watchFields?.countryId?.value || user?.countryId)
    ) {
      setOrangeTick({ ...orangeTick, tick4: 25 });
    }
  }, [watchFields]);

  if (isLoading) return <Loader loading={isLoading} />;

  return (
    <form onSubmit={handleSubmit(handleEditProfile)} id="edit-profile-form">
      <Modal
        className="reset-password-modal"
        show={showResetPasswordModal}
        onHide={() => setShowResetPasswordModal(false)}
        centered
      >
        <Modal.Header className="border-0 p-3" closeButton>
          <Modal.Title className="text-white font-24 ">
            Reset Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <ResetPasswordForm
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            resetMode={"loggedOn"}
            setShowResetPasswordModal={setShowResetPasswordModal}
          />
        </Modal.Body>
      </Modal>

      <Row className="d-flex justify-content-between">
        <Col
          xs={12}
          // md={8}
          className="container-cover-image  d-flex flex-column align-items-center justify-content-end h-75 --border-right-1px-solid px-4"
        >
          {/* <div className="d-flex justify-content-between w-100">
            <label className="font--inter-semiBold text-black font-16">
              Cover Picture <span className="text-danger">*</span>
            </label>
            <FontAwesomeIcon
              className="text-danger --cursor-pointer"
              icon={faTrashAlt}
              onClick={() => setHeaderThumbnail()}
            />
          </div> */}

          <Col xs={12}>
            <input
              onChange={(e) => onHeaderImageChange(e, clearErrors)}
              type="file"
              className="d-none"
              accept="image/*"
              id="header-image"
            />
            <label
              id="header-image-label"
              htmlFor="header-image"
              className="w-100 my-3 d-flex align-items-center justify-content-center p-5 mb-3 font--inter-medium font-16 blue-text rounded blue-border --border-3px-dashed --cursor-pointer --bg-no-repeat"
              style={{
                background: `url(${
                  headerImage?.filePreview ||
                  profile?.profileBannerUrl ||
                  "/assets/images/placeholders/profile-banner.png"
                })`,
                height: 175,
                backgroundSize: "100% 100%",
              }}
            >
              {!headerImage && (
                <div className="d-flex flex-column align-items-center">
                  <img
                    src="/assets/icons/camera.png"
                    alt="camera"
                    className="mb-2"
                    width={15}
                    height={15}
                  />
                  <span>Profile Cover Picture</span>
                </div>
              )}
            </label>
            <div className="text-center text-danger">
              {errors?.headerImage && errors.headerImage.message}
            </div>
          </Col>
        </Col>

        <hr className="my-4" />

        <div className="mb-3">
          <div className="mb-5 font-26 --text-underline">Account Info</div>

          <AccountInfoFields
            user={user}
            register={register}
            selectedPhoneNumberPrefix={selectedPhoneNumberPrefix}
            setSelectedPhoneNumberPrefix={setSelectedPhoneNumberPrefix}
            phonePrefixes={phonePrefixes}
            profileOptions={profileOptions}
            profileImage={profileImage}
            setProfileThumbnail={setProfileThumbnail}
            onProfileImageChange={onProfileImageChange}
            errors={errors}
            clearErrors={clearErrors}
          />
        </div>

        <hr className="my-4" />
        <div className="mb-3">
          <div className="mb-5 font-26 --text-underline">Personal Info</div>
          <Row>
            <Col>
              <label htmlFor="form-input" className="form-label">
                Date of Birth <span className="text-danger">*</span>
              </label>
              <DatePicker
                startDate={dateOfBirth}
                setStartDate={setDateOfBirth}
              />
            </Col>

            <Col>
              <label htmlFor="form-input" className="form-label">
                Education Level <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`form-control p-0 m-0 border-0 --form-properties ${
                      !errors.educationLevelId && "mb-4"
                    }`}
                    // isMulti
                    value={selectedEducationLevel}
                    id="educationLevelId"
                    name="educationLevelId"
                    placeholder="Select the educational level"
                    options={educationLevelOptions}
                    onChange={(selectedOption) => {
                      setSelectedEducationLevel([selectedOption]);
                      onChange(selectedOption);
                    }}
                  />
                )}
                {...register(
                  "educationLevelId",
                  profileOptions.educationLevelId
                )}
              />
              {errors.educationLevelId && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.educationLevelId?.message}
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="mb-3">
          <Row>
            <Col>
              <label htmlFor="form-input" className="form-label">
                Preferred Employment Type <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`form-control p-0 m-0 border-0 --form-properties ${
                      !errors.creativesEmploymentTypes && "mb-4"
                    }`}
                    // isMulti
                    value={selectedEmploymentType}
                    id="creativesEmploymentTypes"
                    name="creativesEmploymentTypes"
                    placeholder={"Select the employment type"}
                    options={employmentTypeOptions}
                    onChange={(selectedOption) => {
                      setSelectedEmploymentType([selectedOption]);
                      onChange([selectedOption]);
                    }}
                  />
                )}
                {...register(
                  "creativesEmploymentTypes",
                  profileOptions.creativesEmploymentTypes
                )}
              />
              {errors.creativesEmploymentTypes && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.creativesEmploymentTypes?.message}
                </div>
              )}
            </Col>

            <Col>
              <label htmlFor="form-input" className="form-label">
                Expected Salary <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`form-control p-0 m-0 border-0 --form-properties ${
                      !errors.creativesSalaryRanges && "mb-4"
                    }`}
                    // isMulti
                    value={selectedSalaryRange}
                    id="creativesSalaryRanges"
                    name="creativesSalaryRanges"
                    placeholder={"Select the salary range"}
                    options={creativesSalaryRangesOptions}
                    onChange={(selectedOption) => {
                      setSelectedSalaryRange([selectedOption]);
                      onChange([selectedOption]);
                    }}
                  />
                )}
                {...register(
                  "creativesSalaryRanges",
                  profileOptions.creativesSalaryRanges
                )}
              />
              {errors.creativesSalaryRanges && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.creativesSalaryRanges?.message}
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="mb-3">
          <Row>
            <Col xs={12}>
              <label htmlFor="form-input" className="form-label">
                About me (don't be shy) <span className="text-danger">*</span>
              </label>
              <textarea
                {...register("bio", profileOptions.bio)}
                rows={8}
                className="form-control"
                placeholder="Write something about yourself"
                id="bio"
                value={profileOptions.bio && profileOptions.bio.value}
              />

              {errors.bio && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.bio?.message}
                </div>
              )}
            </Col>
          </Row>
        </div>

        <hr className="my-4" />

        <div className="mb-3">
          <div className="mb-5 font-26 --text-underline">Career Details</div>
          <Row>
            <Col>
              <label htmlFor="form-input" className="form-label">
                Company <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="currentPositionCompany"
                placeholder="Name your title or position"
                {...register(
                  "currentPositionCompany",
                  profileOptions.currentPositionCompany
                )}
              />
              {errors.currentPositionCompany && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.currentPositionCompany?.message}
                </div>
              )}
            </Col>
            <Col>
              <label htmlFor="form-input" className="form-label">
                Job Type <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`form-control p-0 m-0 border-0 --form-properties ${
                      !errors.currentEmploymentTypeId && "mb-4"
                    }`}
                    // isMulti
                    value={selectedCurrentEmploymentType}
                    id="currentEmploymentTypeId"
                    name="currentEmploymentTypeId"
                    placeholder={"Select the job type"}
                    options={employmentTypeOptions}
                    onChange={(selectedOption) => {
                      setSelectedCurrentEmploymentType([selectedOption]);
                      onChange(selectedOption);
                    }}
                  />
                )}
                {...register(
                  "currentEmploymentTypeId",
                  profileOptions.currentEmploymentTypeId
                )}
              />
              {errors.currentEmployment && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.currentEmployment?.message}
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="mb-3">
          <Row>
            <Col>
              <label htmlFor="form-input" className="form-label">
                Title / Position <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="currentPositionTitle"
                placeholder="Name your title or position"
                {...register(
                  "currentPositionTitle",
                  profileOptions.currentPositionTitle
                )}
              />
              {errors.currentPositionTitle && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.currentPositionTitle?.message}
                </div>
              )}
            </Col>
            {/* <Col>
              <label htmlFor="form-input" className="form-label">
                Company Details <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="currentPositionDetails"
                placeholder="Name your title or position"
                {...register(
                  "currentPositionDetails",
                  profileOptions.currentPositionDetails
                )}
              />
              {errors.currentPositionDetails && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.currentPositionDetails?.message}
                </div>
              )}
            </Col> */}
          </Row>
        </div>

        <hr className="my-4" />

        <div className="mb-3">
          <div className="mb-5 font-26 --text-underline">Location & Links</div>
          <Row>
            <Col>
              <label htmlFor="form-input" className="form-label">
                Country <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`form-control p-0 m-0 border-0 --form-properties ${
                      !errors.countryId && "mb-4"
                    }`}
                    // isMulti
                    value={selectedCountry}
                    id="countryId"
                    name="countryId"
                    placeholder={"Select your country"}
                    options={countriesOptions}
                    onChange={(selectedOption) => {
                      setSelectedCountry(selectedOption);
                      onChange(selectedOption);
                    }}
                  />
                )}
                {...register("countryId", profileOptions.countryId)}
              />
              {errors.countryId && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.countryId?.message}
                </div>
              )}
            </Col>

            <Col>
              <label htmlFor="form-input" className="form-label">
                City <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`form-control p-0 m-0 border-0 --form-properties ${
                      !errors.stateId && "mb-4"
                    }`}
                    // isMulti
                    value={selectedState}
                    id="stateId"
                    name="stateId"
                    placeholder={"Select your state"}
                    options={stateOptions}
                    onChange={(selectedOption) => {
                      setSelectedState(selectedOption);
                      onChange(selectedOption);
                    }}
                  />
                )}
                {...register("stateId", profileOptions.stateId)}
              />
              {errors.stateId && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.stateId?.message}
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="mb-3">
          <Row>
            <Col>
              <label htmlFor="form-input" className="form-label">
                Nationality <span className="text-danger">*</span>
              </label>
              <Controller
                control={control}
                render={({ field: { onChange } }) => (
                  <Select
                    className={`form-control p-0 m-0 border-0 --form-properties ${
                      !errors.nationalityId && "mb-4"
                    }`}
                    // isMulti
                    value={selectedNationality}
                    id="nationalityId"
                    name="nationalityId"
                    placeholder={"Select your Nationality"}
                    options={nationalitiesOptions}
                    onChange={(selectedOption) => {
                      setSelectedNationality(selectedOption);
                      onChange(selectedOption);
                    }}
                  />
                )}
                {...register("nationalityId", profileOptions.nationalityId)}
              />
              {errors.nationalityId && (
                <div className="text-danger font-14 mt-2 mb-4 mx-1">
                  {errors.nationalityId?.message}
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div className="mb-3">
          <div className="mb-5 font-26 --text-underline">Account Info</div>
          <Row>
            <Col>
              <div className="form-group mb-3">
                <label
                  className="font--inter-semiBold text-black font-16 mb-2"
                  htmlFor=""
                >
                  Website Links
                </label>
                <div>
                  <div className="form-group mb-3">
                    {existingLinks?.map((link, index) => (
                      <Row className="d-flex align-items-center">
                        <Col xs={10}>
                          <input
                            key={index}
                            type="text"
                            className="form-control my-2"
                            placeholder="Enter website link here"
                            defaultValue={link.value}
                            onChange={(e) => handleExistingLinkChange(e, index)}
                          />
                        </Col>
                        <Col xs={2}>
                          <select
                            id={`existingUserLinkType${index}`}
                            name={`existingUserLinkType${index}`}
                            defaultValue={link?.userLinkType || "WEBSITE"}
                            className="bg-transparent border-0"
                            onChange={(e) => handleExistingLinkChange(e, index)}
                          >
                            <option value="WEB">Website</option>
                            <option value="FACEBOOK">Facebook</option>
                            <option value="LINKEDIN">LinkedIn</option>
                            <option value="INSTAGRAM">Instagram</option>
                            <option value="YOUTUBE">Youtube</option>
                            <option value="TIKTOK">TikTok</option>
                          </select>
                        </Col>
                      </Row>
                    ))}
                    {links.map((link, index) => (
                      <Row className="d-flex align-items-center">
                        <Col xs={10}>
                          <input
                            key={index}
                            type="text"
                            className="form-control my-2"
                            placeholder="Enter website link here"
                            value={link.value}
                            onChange={(e) => handleLinkChange(e, index)}
                          />
                        </Col>
                        <Col xs={2}>
                          <select
                            id={`userLinkType${index}`}
                            name={`userLinkType${index}`}
                            defaultValue="Youtube"
                            // value={selectedPhoneNumberPrefix}
                            className="bg-transparent border-0"
                            onChange={(e) => handleLinkChange(e, index)}
                          >
                            <option value="WEB">Website</option>
                            <option value="FACEBOOK">Facebook</option>
                            <option value="LINKEDIN">LinkedIn</option>
                            <option value="INSTAGRAM">Instagram</option>
                            <option value="YOUTUBE">Youtube</option>
                            <option value="TIKTOK">TikTok</option>
                          </select>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>
                <div>
                  <button
                    onClick={handleAddLink}
                    type="button"
                    id="add-link-button"
                    className="text-black text-decoration-none p=0 bg-transparent border-0 font-14"
                  >
                    <img src="/assets/icons/plus.png" alt="plus" /> Add Link
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <hr className="my-4" />

        <Col xs={12} md={6}>
          <button
            type="submit"
            className="btn border-1 border-radius-0 p-1 ps-3 pe-3 fw-700 mb-2 w-100 --variant-dark rounded"
          >
            Save
          </button>
        </Col>
        <Col xs={12} md={6}>
          <Button
            className="btn border-1 border-radius-0 p-1 ps-3 pe-3 fw-700 mb-2 w-100 --variant-light rounded"
            onClick={() => setShowResetPasswordModal(true)}
          >
            Advanced Settings
          </Button>
        </Col>
      </Row>
    </form>
  );
};

const mapStateToProps = (props, parentProps) => ({
  isLogged: props.userReducer.isLoggedIn,
  user: props.userReducer.user,
  phonePrefixes: props.utilsReducer.phoneNumberPrefixes,
  dropdownFilters: props.jobReducer.dropdownFilters,
});

const mapDispatchToProps = (dispatch) => ({
  jobActions: bindActionCreators(jobActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
  utilsActions: bindActionCreators(utilsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileDetails);
