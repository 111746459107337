import { useState } from "react";
import Modal from 'react-bootstrap/Modal';

export default function Download(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button type="button" className={props.className} onClick={handleShow}>
                {
                    props.placement && props.placement === "mobile-menu"
                        ? <img className={"me-3"} src="/assets/icons/download.png" alt="mobile logo" style={{ width: "26px" }} />
                        : ""
                }
                {props.text ?? "Download App"}
            </button>
            <Modal className={"logout-modal"} show={show} onHide={handleClose} centered>
                <Modal.Header className={"border-0 p-3 mb-5"} closeButton></Modal.Header>
                <Modal.Body className={"p-3"}>
                    <p className="text-white text-center font-24 fw-700 m-0">
                        Find out more at
                    </p>
                    <img className="w-300px mx-auto mt-2 mb-5 d-block" src="/assets/images/logos/logo-white.png" alt="logo" />
                    <div className="text-center mb-5">
                        <a className="me-3" href="apple" target="_blank">
                            <img className="w-150px mb-5" src="/assets/images/download-apple.png" alt="logo" />
                        </a>
                        <a className="me-0" href="google" target="_blank">
                            <img className="w-150px mb-5" src="/assets/images/download-google.png" alt="logo" />
                        </a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
