import { useEffect, useState } from "react";
import { detectSocialMedia } from "../../utils/getSocialType";
import ShareModal from "../Modals/ShareModal";
import Loader from "../Commons/Loader";
import { Link } from "react-router-dom";

export default function ProfileSidebar({ employerProfile }) {
  const [showShareModal, setShowShareModal] = useState(false);
  const [website, setWebsite] = useState();

  const handleCheckLinks = () => {
    let website = employerProfile.websiteUrl;
  };

  const handleClose = (type) => {
    setShowShareModal(false);
  };

  if (!employerProfile?.id) return <Loader loading={true} />;

  return (
    <>
      <ShareModal
        title="Share Profile"
        showShareModal={showShareModal}
        handleClose={handleClose}
        shareLink={`${process.env.REACT_APP_BASE_URI}/employer/${employerProfile.id}`}
      />
      <div id="profile-sidebar" className="d-none d-md-block">
        <div className="ps-image mb-3">
          <img
            src={employerProfile?.profileImageUrl || "/assets/icons/1.png"}
            alt="Profile"
            className={"maxWidth-200px w-100 rounded"}
          />
        </div>
        <div className="ps-title mb-2">
          <p className={"font-26 fw-700"}>{employerProfile?.name}</p>
        </div>
        <div className="ps-job mb-5 fw-700">
          <p className={"font-16 text-secondary m-0"}>
            @{employerProfile?.username}
          </p>
        </div>
        <div className="ps-social-links mb-2">
          <div className="ps-social-links-container d-flex flex-wrap justify-content-center">
            {employerProfile?.additionalLinksList?.map((link) => (
              <a
                className="ps-social-link p-1 m-1"
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`/assets/icons/profile-social-icons/${detectSocialMedia(
                    link
                  )}.svg`}
                  width={20}
                  height={20}
                  alt={detectSocialMedia(link)}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="ps-button mb-4">
          {employerProfile?.websiteUrl && (
            <Link
              to={employerProfile.websiteUrl}
              target="_blank"
              className="w-100 btn bg-transparent border-1 p-1 ps-3 pe-3 text-black border-dark fw-700 mb-2"
            >
              Visit Website
            </Link>
          )}
          <button
            className="w-100 btn bg-blue border-1 p-1 ps-3 pe-3 text-white border-dark fw-700"
            onClick={() => setShowShareModal(true)}
          >
            Share
          </button>
        </div>
      </div>
      <div className="employer-details">
        <p className="font-20 text-black fw-700 mb-2">Company Size</p>
        <p className="font-18 text-black mb-4">
          {employerProfile?.employerCompanySize}
        </p>
        <p className="font-20 text-black fw-700 mb-2">Company Address</p>
        <p className="font-18 text-black mb-4">
          {employerProfile?.businessAddress}
        </p>
      </div>
    </>
  );
}
