import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import * as Actions from "../redux/actions/userAction";
import * as utilsActions from "../redux/actions/utilsAction";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

import LoginLayout from "../components/Layouts/LoginLayout";
import SocialLogins from "../components/Commons/SocialLogins";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Signup = ({ actions, isLogged, phonePrefixes }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedPhoneNumberPrefix, setSelectedPhoneNumberPrefix] =
    useState("1");

  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const handleSignup = async (data) => {
    if (!data.name) {
      data.name = data?.firstName + " " + data?.lastName;
    }

    data.phoneNumberDataId = selectedPhoneNumberPrefix;

    const signupStatus = await actions.signup(data);

    signupStatus === "ok"
      ? navigate("/login", { replace: true })
      : setErrorMessage(signupStatus || "Failed to Create Account");
  };
  const signupOptions = {
    firstName: { required: "First Name is required" },
    lastName: { required: "Last Name is required" },
    username: { required: "Username is required" },
    email: { required: "Email is required" },
    phoneNumber: { required: "Phone Number is required" },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters",
      },
    },
    confirmPassword: {
      required: "Confirm Password is required",
      validate: (val) => {
        if (watch("password") !== val) {
          return "Your passwords do no match";
        }
      },
    },
  };
  const getToken = () => {
    const token = localStorage.getItem("token2");
    if (token) {
      navigate("/", { replace: true });
    }
  };
  useEffect(() => {
    getToken();
    phonePrefixes?.length < 1 &&
      dispatch(utilsActions.getPhoneNumberPrefixes());
  }, [dispatch]);

  return (
    <>
      <LoginLayout>
        <form
          onSubmit={handleSubmit(handleSignup)}
          className={"ls-form signup-form w-100"}
        >
          <h3 className={"text-center mb-4 fw-700"}>Sign Up</h3>
          <p className={"text-left mb-2 font-20 fw-700"}>
            Personal Information
          </p>
          <div className="form-group mb-3 row">
            <Col>
              <input
                name="firstName"
                type="text"
                className="form-control"
                id="first_name"
                placeholder="First Name *"
                {...register("firstName", signupOptions.firstName)}
              />
              <small className="text-danger">
                {errors?.firstName && errors.firstName.message}
              </small>
            </Col>
            <Col>
              <input
                type="text"
                className="form-control"
                id="last_name"
                placeholder="Last Name *"
                name="lastName"
                {...register("lastName", signupOptions.lastName)}
              />
              <small className="text-danger">
                {errors?.lastName && errors.lastName.message}
              </small>
            </Col>
          </div>
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="Username *"
              name="username"
              {...register("username", signupOptions.username)}
            />
            <small className="text-danger">
              {errors?.username && errors.username.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email *"
              name="email"
              {...register("email", signupOptions.email)}
            />
            <small className="text-danger">
              {errors?.email && errors.email.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password *"
              name="password"
              {...register("password", signupOptions.password)}
            />
            <small className="text-danger">
              {errors?.password && errors.password.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <input
              type="password"
              className="form-control"
              id="confirm_password"
              placeholder="Confirm Password *"
              name="confirmPassword"
              {...register("confirmPassword", signupOptions.confirmPassword)}
            />
            <small className="text-danger">
              {errors?.confirmPassword && errors.confirmPassword.message}
            </small>
          </div>
          <div className="form-group mb-3 d-flex">
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <select
                  name="phoneNumberDataId"
                  defaultValue={selectedPhoneNumberPrefix}
                  value={selectedPhoneNumberPrefix}
                  className="select-user-profile-phone-prefix bg-transparent"
                  onChange={({ currentTarget: { value } }) => {
                    setSelectedPhoneNumberPrefix(value);
                    onChange(value);
                  }}
                >
                  {phonePrefixes?.length > 0 &&
                    phonePrefixes
                      .sort((a, b) => a.callingCode - b.callingCode)
                      .map((phonePrefix) => (
                        <option key={phonePrefix.id} value={phonePrefix.id}>
                          {phonePrefix.callingCode}
                        </option>
                      ))}
                </select>
              )}
              {...register(
                "phoneNumberDataId",
                signupOptions.phoneNumberDataId
              )}
            />
            <input
              type="text"
              className="form-control"
              id="phone_number"
              placeholder="Phone Number *"
              name="phoneNumber"
              {...register("phoneNumber", signupOptions.phoneNumber)}
            />
            <small className="text-danger">
              {errors?.phoneNumber && errors.phoneNumber.message}
            </small>
          </div>

          <Row className="m-0 mb-1 justify-content-center">
            <button
              className="p-2 ps-3 pe-3 bg-blue text-white border-1 border-accent border-radius-20 fw-700"
              type="submit"
            >
              Create Account
            </button>
            <span className="text-danger --text-16 text-center">
              {errorMessage}
            </span>
          </Row>

          <Row className="m-0 mb-1 mt-3 justify-content-center">
            <p className="text-secondary text-center fw-700">
              Got Account? Login{" "}
              <Link className="text-black text-decoration-none" to="/login">
                here
              </Link>
            </p>
          </Row>

          <div className="--text-center-line-through-lr mb-3">
            <span>Or with</span>
          </div>

          <Row
            onClick={(e) => e.preventDefault()}
            className="m-0 mb-3 social-login-container justify-content-center"
          >
            <SocialLogins
              type="signup"
              actions={actions}
              setErrorMessage={setErrorMessage}
            />
          </Row>

          <Row className="m-0 mb-2 justify-content-center">
            <p className="text-secondary text-center fw-700">
              By pressing “Create Account”, you agree to our Terms and
              Conditions made available{" "}
              <Link
                className="text-black text-decoration-none"
                target="_blank"
                to="https://www.cultcreative.asia/terms-and-conditions"
              >
                here.
              </Link>
            </p>
          </Row>
        </form>
      </LoginLayout>
    </>
  );
};

const mapStateToProps = (props) => ({
  isLogged: props.userReducer.isLoggedIn,
  phonePrefixes: props.utilsReducer.phoneNumberPrefixes,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
  utilsActions: bindActionCreators(utilsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
