import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Listing from "../components/job/Listing";
import JobDetailCard from "../components/job/Details";
import React, { useEffect, useState } from "react";
import * as Actions from "../redux/actions/jobAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as jobActions from "../redux/actions/jobAction";

const Home = ({ actions, isLoggedIn, selectedJob, route }) => {
  const dispatch = useDispatch();
  const [selectedJobData, setSelectedJobData] = useState({});
  const [selectedRoute, setSelectedRoute] = useState("");
  const [showCanvas, setShowCanvas] = useState(false);
  const [initialJobListing, setInitialJobListing] = useState();

  const { jobQuery, jobListing, pageIndex, pageSize } = useSelector(
    ({ jobReducer }) => jobReducer
  );

  const getSelectedJob = (id) => {
    actions.getSelectedJobById(id);
  };

  const changeToMobileDisplay = () => {
    setShowCanvas(!showCanvas);
  };
  useEffect(() => {
    setSelectedJobData(selectedJob);
  }, [selectedJob]);

  useEffect(() => {
    setSelectedRoute(route);
  }, [route]);

  useEffect(() => {
    jobListing.length > 0 &&
      !initialJobListing &&
      setInitialJobListing(jobListing);
  }, [jobListing]);

  useEffect(() => {
    if (jobQuery?.length < 2) {
      if (initialJobListing) {
        dispatch(jobActions.setPageIndex(0));
        dispatch(jobActions.setAllJobs(initialJobListing));
      }

      const jobs = {
        pageIndex: pageIndex + 1,
        pageSize: pageSize,
        keyword: jobQuery,
      };
      jobActions.getAllJobs("home", { keyword: jobQuery }, "filter");
    }
  }, [jobQuery]);

  return (
    <>
      <Container className={"home-listing-container mt-4"}>
        <Row>
          <Col sm={12} md={4}>
            <Listing
              changeToMobileDisplay={changeToMobileDisplay}
              route={selectedRoute}
              getSelectedJob={getSelectedJob}
            />
          </Col>
          <Col className={"d-none d-md-block"} sm={8}>
            <JobDetailCard
              changeToMobileDisplay={changeToMobileDisplay}
              showCanvas={showCanvas}
              data={selectedJobData}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (props, parentProps) => ({
  isLoggedIn: props.userReducer.isLoggedIn,
  selectedJob: props.jobReducer.selectedJob,
  route: parentProps.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
