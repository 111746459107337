export const verifyEmbedVideoUrl = (url) => {
  if (!url) return "Video url is required";

  const urlDomain = new URL(url).hostname;

  const sameOrigin = [
    window.location.host,
    "dev.ccwl.cultcreative.asia",
    "stg.ccwl.cultcreative.asia",
    "web.cultcreative.asia",
  ];

  const isValidProtocol = /^(http|https):\/\/[^ "]+$/.test(url);
  if (!isValidProtocol) return "Invalid URL";

  if (sameOrigin.includes(urlDomain))
    return "Sharing link of the same origin is not allowed";
};
