import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as UserActions from "../../redux/actions/userAction";
import * as JobActions from "../../redux/actions/jobAction";
import Card from "./Card";
import { FILTER_BY_JOB } from "../../constants/action-types";

const Listing = ({
  // totalCount,
  jobActions,
  allJobs,
  getSelectedJob,
  route,
  changeToMobileDisplay,
  jobStatus,
  hasNextPage,
}) => {
  const dispatch = useDispatch();
  const [jobLocalListing, setJobLocalListing] = useState([]);

  const { jobQuery, jobListing, pageIndex, pageSize } = useSelector(
    ({ jobReducer }) => jobReducer
  );

  const selectedJob = (value) => {
    getSelectedJob(value);
  };
  useEffect(() => {
    setJobLocalListing([]);
    if (route) {
      const jobs = {
        pageIndex: pageIndex,
        pageSize: pageSize,
      };
      jobActions.getAllJobs(route, jobs, "listing");
    }
  }, [route]);

  useEffect(() => {
    if (allJobs && allJobs.length) {
      if (jobStatus === FILTER_BY_JOB) {
        setJobLocalListing(allJobs);
        selectedJob(
          jobLocalListing.length
            ? jobLocalListing[0]["jobId"]
            : allJobs[0]["jobId"]
        );
      } else {
        let jobs = [...jobLocalListing];

        jobs.push(...allJobs);

        if (!jobQuery && pageIndex === 0) {
          jobs = allJobs;
        }

        setJobLocalListing(jobs);

        selectedJob(
          jobLocalListing.length
            ? jobLocalListing[0]["jobId"]
            : allJobs[0]["jobId"]
        );
      }
    }
  }, [allJobs]);

  const loadMore = () => {
    if (jobListing?.length < 1) return;
    dispatch(JobActions.setPageIndex(pageIndex + 1));
    const jobs = {
      pageIndex: pageIndex + 1,
      pageSize: pageSize,
      keyword: jobQuery,
    };
    jobActions.getAllJobs(route, jobs, "listing");
  };

  return (
    <>
      {/* {(!jobQuery ? jobLocalListing : allJobs).map((job, index) => { */}
      {jobLocalListing.map((job, index) => {
        return (
          <Card
            key={index}
            changeToMobileDisplay={changeToMobileDisplay}
            selectedJob={selectedJob}
            data={job}
          />
        );
      })}

      {/* {(pageIndex + 1) * pageSize < totalCount && ( */}
      <button
        disabled={!hasNextPage}
        onClick={loadMore}
        type="button"
        className="showmore-btn btn font-family-futuram btn bg-blue border-1 border-accent p-1 ps-3 pe-3 text-white me-2 font-20 w-100"
      >
        {hasNextPage ? "Show More" : "End of List"}
      </button>
      {/* )} */}
    </>
  );
};

const mapStateToProps = (props, parentProps) => ({
  isLogged: props.userReducer.isLoggedIn,
  userData: props.userReducer.user,
  allJobs: props.jobReducer.jobListing,
  totalCount: props.jobReducer.totalCount,
  jobStatus: props.jobReducer.status,
  route: parentProps.route,
  hasNextPage: props.jobReducer.hasNextPage,
});

const mapDispatchToProps = (dispatch) => ({
  userActions: bindActionCreators(UserActions, dispatch),
  jobActions: bindActionCreators(JobActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
