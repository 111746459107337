import { Link, useLocation, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../Commons/Loader";

export default function ProfileSidebar(props) {
  const { pathname } = useLocation();
  const params = useParams();
  const [isCopying, setIsCopying] = useState(false);

  const user = useSelector(({ userReducer }) => userReducer.user);

  const handleCopyProfileLink = () => {
    setIsCopying(true);
    navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URI}/profile/${user.id}`);

    setTimeout(() => {
      setIsCopying(false);
    }, 2000);
  };

  if (!user) return <Loader loading={true} />;

  return (
    <div id="profile-sidebar" className="d-none d-md-block">
      <div className="ps-image mb-3">
        <img
          src={props?.user?.profileImageUrl || "/assets/icons/1.png"}
          onError={(e) => (e.currentTarget.src = "/assets/icons/1.png")}
          alt="Profile"
          className={"maxWidth-200px w-100 rounded"}
        />
      </div>
      <div className="ps-title mb-2 text-break">
        <p className={"font-26 fw-700"}>{props?.user.name}</p>
      </div>
      <div className="ps-job mb-5 fw-700 text-break">
        <p className={"font-16 text-secondary m-0"}>
          {props?.user.currentPositionTitle}
        </p>
        <p className={"font-16 text-secondary m-0 text-break"}>@{props?.user.username}</p>
      </div>
      <div className="ps-social-links mb-2">
        <div className="ps-social-links-container d-flex flex-wrap justify-content-center">
          {props?.user?.userLinks
            ?.filter(
              (ul) =>
                ul.userLinkType === "WEB" ||
                ul.userLinkType === "YOUTUBE" ||
                ul.userLinkType === "FACEBOOK" ||
                ul.userLinkType === "LINKEDIN" ||
                ul.userLinkType === "BEHANCE" ||
                ul.userLinkType === "TIKTOK" ||
                ul.userLinkType === "INSTAGRAM"
            )
            .map((link, i) => (
              <a
                key={i}
                className="ps-social-link p-1 m-1"
                href={link.value}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`/assets/icons/profile-social-icons/${link.userLinkType}.svg`}
                  alt={link.userLinkType}
                  className="p-0 m-0"
                  width={20}
                  height={20}
                />
              </a>
            ))}
        </div>
      </div>
      <div className="ps-button">
        {!pathname.includes("edit") && !params.user_id ? (
          <div className="ps-button">
            <Link
              to="/profile/edit"
              className="w-100 btn bg-transparent border-1 p-1 ps-3 pe-3 text-black border-dark fw-700 mb-2"
            >
              Edit Profile
            </Link>
            {/* <button
            className={
              "w-100 btn bg-blue border-1 p-1 ps-3 pe-3 text-white border-dark fw-700"
            }
          >
            Share
          </button> */}
          </div>
        ) : params?.user_id ? (
          <></>
        ) : (
          <Link
            to="/profile"
            className="w-100 btn bg-transparent border-1 p-1 ps-3 pe-3 text-black border-dark fw-700 mb-2"
          >
            Profile Overview
          </Link>
        )}
        <Button
          onClick={handleCopyProfileLink}
          className={`w-100 btn ${
            isCopying ? "bg-success border-0" : "bg-blue border-dark"
          }  border-1 p-1 ps-3 pe-3 text-white fw-700 mb-2`}
        >
          {isCopying ? (
            <>
              <FontAwesomeIcon icon={faCheck} className="text-white" /> Profile
              Link Copied
            </>
          ) : (
            "Share Profile"
          )}
        </Button>
      </div>
    </div>
  );
}
