import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const truncateText = (text, limit) => {
  if (text?.length <= limit) {
    return text;
  } else {
    const truncated = text?.substr(0, limit);
    const lastSpaceIndex = truncated?.lastIndexOf(" ");
    return truncated?.substr(0, lastSpaceIndex) + "...";
  }
};

const ProjectCard = ({ data }) => {
  const truncatedTitle = truncateText(data.title, 45);
  const truncatedDescription = truncateText(data.description, 80);

  return (
    <div className="card project-card --semi-transparent">
      <Link
        to={"/project/" + data.id}
        className="text-black text-decoration-none"
      >
        <motion.div
          className="glassmorphism-card"
          initial={{
            scale: 1,
          }}
          whileHover={{
            // rotateY: [0, 360],
            scale: 1.05,
          }}
          transition={
            {
              // duration: 0.01,
              // repeat: 1,
              // repeatType: "loop",
            }
          }
        >
          <Card className="--semi-transparent">
            <Card.Img
              variant="top"
              src={data?.imageUrl || "/assets/icons/1.png"}
              onError={(e) => (e.target.src = "/assets/icons/1.png")}
              className="card-img"
            />
            <div className="card-body-wrapper">
              <Card.Body className="card-body-project">
                <Card.Title>{truncatedTitle}</Card.Title>
                <Card.Text className="card-project-description">
                  {truncatedDescription}
                </Card.Text>
              </Card.Body>
            </div>
          </Card>
        </motion.div>
      </Link>
    </div>
  );
};

export default ProjectCard;
