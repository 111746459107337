import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Logo from "../Commons/Logo.js";
import Menu from "../Menu/MenuDesktop.js";
import SearchBar from "../Search/SearchBar.js";
import { useCallback, useEffect, useRef, useState } from "react";

export default function HeaderDesktop(props) {
  const [posY, setPosY] = useState(window.scrollY);

  const navRef = useRef(null);

  const handleHeaderScroll = useCallback(
    (e) => {
      if (posY < window.scrollY && window.scrollY > 100) {
        navRef.current.style.zIndex = 0;
        navRef.current.style.opacity = 0;
      } else {
        navRef.current.style.zIndex = 10;
        navRef.current.style.opacity = 1;
      }

      setPosY(window.scrollY);
    },
    [posY]
  );

  useEffect(() => {
    navRef.current.style.zIndex = 1;

    window.addEventListener("scroll", handleHeaderScroll);

    return () => {
      window.removeEventListener("scroll", handleHeaderScroll);
    };
  }, [handleHeaderScroll]);

  return (
    <>
      <div
        ref={navRef}
        className="d-none d-lg-block border-bottom border-dark text-dark border-2 bg-f5f5f5 --sticky-top"
      >
        <Container className="header p-3">
          <Row className="align-items-center justify-content-between">
            <Col sm={7} className="d-flex align-items-center flex-row">
              <Logo
                style={{ height: "40px", width: "auto" }}
                img="desktop-logo"
              />
              <SearchBar
                style={{
                  height: "40px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                }}
              />
            </Col>
            <Col sm={5}>
              <Menu />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
