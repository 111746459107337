import { Modal } from "react-bootstrap";

const ConfirmationModal = ({ showModal, setShowModal, title, children }) => {
  return (
    <Modal
      className="confirmation-modal"
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
    >
      <Modal.Header className="border-0 p-3" closeButton>
        <Modal.Title className="font-24">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">{children}</Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
