import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions/userAction";
import { LOGOUT_SUCCESS } from "../../constants/action-types";
import Modal from "react-bootstrap/Modal";

const Logout = ({ actions, isLogged, status, placement, classes }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogged && status === LOGOUT_SUCCESS) {
      actions.emptyStatus();
      navigate("/", { replace: true });
    }
  }, [actions, status, isLogged, navigate]);

  const logoutFromDesktop = () => {
    actions.logoutAction();
  };

  return (
    <>
      {placement && placement === "mobile-menu" ? (
        <button
          className={`font-16 text-start fw-700 text-black text-decoration-none border-0 p-0 m-0 ${
            classes || ""
          }`}
          onClick={handleShow}
        >
          <img
            className={"me-3"}
            src="/assets/icons/logout.png"
            alt="mobile logo"
          />
          Logout
        </button>
      ) : (
        <button
          className={`text-black text-decoration-none fw-700 me-3 border-0 p-0 w-100 --hover-bg-grey ${
            classes || ""
          }`}
          onClick={handleShow}
        >
          Logout
        </button>
      )}
      <Modal
        className={"logout-modal"}
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header
          className={"border-0 p-3 mb-5 align-items-start"}
          closeButton
        >
          <img
            className="w-200px"
            src="/assets/images/logos/logo-white.png"
            alt="logo"
          />
        </Modal.Header>
        <Modal.Body className={"p-4"}>
          <p className="text-white text-center font-20 fw-700 mb-4">
            Are you sure you would like <br />
            to log out of Cult Creative?
          </p>
          <div className="text-center">
            <div
              onClick={logoutFromDesktop}
              className={
                "btn blue-text bg-white border-accent text-decoration-none p-1 ps-3 pe-3 font-18 fw-700 me-3"
              }
              style={{ cursor: "pointer" }}
            >
              Logout
            </div>
            <div
              onClick={handleClose}
              className={
                "btn bg-blue border-1 border-accent p-1 ps-3 pe-3 text-white font-18 fw-700"
              }
              style={{ cursor: "pointer" }}
            >
              Cancel
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (props, parentProps) => ({
  isLogged: props.userReducer.isLoggedIn,
  placement: parentProps.placement,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
