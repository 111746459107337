import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AddProject from "../pages/Profile/Project/AddProject";
import ProfileLayout from "../pages/Profile/ProfileLayout";
import { useSelector } from "react-redux";
import { useAxios } from "../api/useAxios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditProject from "../pages/Profile/Project/EditProject";
import { Button } from "react-bootstrap";

const Project = ({ viewType, setViewType }) => {
  const { get } = useAxios();
  const params = useParams();

  const [errorMessage, setErrormessage] = useState("");
  const [creativesPortfolios, setCreativesPortfolios] = useState();

  const handleGetPortfolios = async () => {
    setIsloading(true);

    const res = await get("creatives/portfolios");
    res?.status === "ok"
      ? setCreativesPortfolios(res.creativesPortfolios)
      : setErrormessage(res?.errorMessage || "Failed to retrive portfolios");

    setIsloading(false);
  };

  useEffect(() => {
    !creativesPortfolios && handleGetPortfolios();
  }, [creativesPortfolios]);

  const [isLoading, setIsloading] = useState(false);

  if (errorMessage) return <span>{errorMessage}</span>;

  return (
    <ProfileLayout isBannerHidden={true}>
      <Container className="mt-4">
        <h1>{viewType} Portfolio</h1>
        {params?.project_id && (
          <div className="d-flex justify-content-end align-items-center">
            <Button
              variant="dark"
              className="mx-2 font-family-futurabookbt font-14"
              onClick={() => setViewType("Edit")}
            >
              Edit
            </Button>
            <Button
              variant="success"
              className="text-white font-family-futurabookbt font-14"
              onClick={() => setViewType("Add")}
            >
              Add
            </Button>
          </div>
        )}

        <Row>
          <Col xsm={12} className="">
            {params?.project_id && viewType === "Edit" ? (
              <EditProject portfolios={creativesPortfolios} />
            ) : (
              <AddProject data={creativesPortfolios} />
            )}
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
};

export default Project;
