import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions/userAction";
import NwCard from "./NwCard";
import Download from "../Commons/Download";
import { useAxios } from "../../api/useAxios";
import Loader from "../Commons/Loader";
import { Link, useParams } from "react-router-dom";

const Network = ({ user, classes }) => {
  return <NetworkPeople user={user} classes={classes} />;
};

function NetworkPeople(props) {
  const params = useParams();
  const { get } = useAxios();
  const [connectedConnection, setConnectedConnection] = useState([]);

  const handleGetConnections = async () => {
    try {
      const connectionResult = await get("creatives/connections");

      if (
        connectionResult?.status === "ok" &&
        connectionResult?.response?.connectedConnections?.length > 0
      ) {
        setConnectedConnection(
          connectionResult.response.connectedConnections.map((connection) => {
            return {
              userId: connection.connectionUserId,
              name: connection.connectionUserName,
              description: connection.connectionPosition,
              image: "/assets/icons/1.png",
            };
          })
        );
      }
    } catch (error) {
      setConnectedConnection([]);
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetConnections();
  }, []);

  if (!connectedConnection) return <Loader loading={true} />;

  return (
    <div className={props.classes}>
      <div
        className={`d-flex ${
          params?.user_id ? "d-none" : ""
        } d-md-block align-items-center justify-content-between mb-3`}
      >
        <p className="font-16 fw-700 m-0">{props?.user?.name}'s Network</p>
        <Download
          text="View all"
          className="btn bg-transparent border-0 p-1 ps-3 pe-3 text-black border-dark fw-700 d-inline-block d-md-none"
        />
      </div>
      <div className="network-container d-flex d-md-block justify-content-between">
        {connectedConnection?.length > 0 ? (
          connectedConnection.map((nwp, i) => (
            <div key={i} className={"my-4"}>
              <NwCard data={nwp} />
              <a
                href={"/profile/" + nwp.userId}
                className="w-100 btn bg-transparent border-1 p-1 ps-3 pe-3 text-black border-dark d-none d-md-block font-12"
              >
                View Profile <br />
              </a>
            </div>
          ))
        ) : (
          <div
            className={`${
              params?.user_id ? "d-none" : ""
            } text-muted font-14 text-center`}
          >
            Not connected to anyone
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (props, parentProps) => ({
  isLogged: props.userReducer.isLoggedIn,
  classes: parentProps.className,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Network);
